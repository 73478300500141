import { useEffect, useState, useCallback } from "react";
import { VianikoEvent } from "../types/events";
import { Organization } from "../types/organization";
import * as api from "../services/api";

interface EventWithOrganization {
  event: VianikoEvent | null;
  organization: Organization | null;
  isLoading: boolean;
  error: Error | null;
  refetch: () => Promise<void>;
}

export const useEventWithOrganization = (
  eventId: string | undefined
): EventWithOrganization => {
  const [event, setEvent] = useState<VianikoEvent | null>(null);
  const [organization, setOrganization] = useState<Organization | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = useCallback(async () => {
    if (!eventId) {
      setError(new Error("Event ID is required"));
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      const { event, organization } = await api.events.fetchEvent(eventId);
      setEvent(event);
      setOrganization(organization || null);
      setError(null);
    } catch (err) {
      setError(err instanceof Error ? err : new Error("Failed to fetch event"));
    } finally {
      setIsLoading(false);
    }
  }, [eventId, setError, setIsLoading, setEvent, setOrganization]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    event,
    organization,
    isLoading,
    error,
    refetch: fetchData,
  };
};
