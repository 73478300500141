import { useCallback, useEffect, useState } from "react";
import { useCurrentUser } from "../providers/CurrentUserProvider";
import { fetchEventTicketTypesAdmin } from "../services/api/ticketTypes";

export const useTicketTypesAdmin = (eventId: string | undefined) => {
  const [ticketTypes, setTicketTypes] = useState([]);
  const { currentUser } = useCurrentUser();

  const fetch = useCallback(async () => {
    if (!eventId || !currentUser) return;
    const ticketTypes = await fetchEventTicketTypesAdmin(eventId);
    setTicketTypes(ticketTypes);
  }, [eventId, currentUser]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { ticketTypes, refetch: fetch };
};
