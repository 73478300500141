import { Divider } from "@chakra-ui/react";
import { Panel } from "../../../components/Panel";
import { useCurrentOrganization } from "../../../providers/CurrentOrganizationProvider";
import { useCallback, useEffect, useState } from "react";
import { VianikoEvent } from "../../../types/events";
import { startOfWeek } from "date-fns";

import {
  fetchOrganizationEvents,
  PAGE_SIZE_ORGANIZATION_EVENTS,
} from "../../../services/api/organizations";
import { useEventsByDateInTimezone } from "../../../hooks/transformers/useEventsByDateInTimezone";
import { WeekCalendar } from "./WeekCalendar";
import {
  EventCalendarEmptyState,
  EventCalendarList,
} from "../../events/components/EventCalendarList";
import { SkeletonLoader } from "../../../components/forms/SkeletonLoader";

export const OrganizationCalendar: React.FC = () => {
  const { organization } = useCurrentOrganization();
  const [events, setEvents] = useState<VianikoEvent[]>([]);
  const [orgFutureEventCount, setOrgFutureEventCount] = useState<number>(0);
  const [orgFutureEventCountLoaded, setOrgFutureEventCountLoaded] =
    useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [moreToLoad, setMoreToLoad] = useState(false);
  const [loading, setLoading] = useState(false);

  const eventsByDay = useEventsByDateInTimezone(events);

  // Filter events to only include those from the selected date onwards
  // or events that end on or after the selected date
  const eventsByDayFromSelectedDate = eventsByDay.filter((eventDay) => {
    const eventDate = new Date(eventDay.year, eventDay.month - 1, eventDay.day);
    const selectedDateStart = new Date(selectedDate);
    selectedDateStart.setHours(0, 0, 0, 0);
    
    // Keep the day if it's on or after the selected date
    if (eventDate >= selectedDateStart) {
      return true;
    }
    
    // Or if any event in this day has an end_at date on or after the selected date
    return eventDay.events.some(event => {
      const endDate = new Date(event.end_at);
      return endDate >= selectedDateStart;
    });
  });

  const getMondayOfCurrentWeek = () => {
    // 1 represents Monday
    return startOfWeek(new Date(), { weekStartsOn: 1 });
  };

  const fetchEvents = useCallback(
    async ({
      lastEventId,
      startAt,
    }: {
      lastEventId?: string;
      startAt?: string;
    }) => {
      if (!organization) return;

      setLoading(true);

      const nextEvents = await fetchOrganizationEvents(organization.id, {
        lastEventId,
        startAt,
      });
      setLoading(false);

      return nextEvents;
    },
    [organization]
  );

  const fetchAndReplaceEvents = useCallback(
    async ({
      lastEventId,
      startAt,
    }: {
      lastEventId?: string;
      startAt?: string;
    }) => {
      const nextEvents = await fetchEvents({ lastEventId, startAt });
      if (nextEvents.length === 0) {
        setMoreToLoad(false);
      } else if (nextEvents.length < PAGE_SIZE_ORGANIZATION_EVENTS) {
        setMoreToLoad(false);
      } else {
        setMoreToLoad(true);
      }
      setEvents(nextEvents);
      if (!orgFutureEventCountLoaded) {
        setOrgFutureEventCountLoaded(true);
        setOrgFutureEventCount(nextEvents.length);
      }
    },
    [fetchEvents, orgFutureEventCountLoaded]
  );

  useEffect(() => {
    if (!organization) return;
    fetchAndReplaceEvents({}); // Initial load without lastEventId
  }, [organization, fetchAndReplaceEvents]);

  const handleDaySelected = (selectedDate: Date) => {
    setSelectedDate(selectedDate);
  };

  const handleNextWeek = (startAt: string) => {
    fetchAndReplaceEvents({ startAt });
    setSelectedDate(new Date(startAt));
  };

  const handlePreviousWeek = (startAt: string) => {
    fetchAndReplaceEvents({ startAt });
    setSelectedDate(new Date(startAt));
  };

  const handleLoadMore = async () => {
    if (events.length > 0) {
      const nextEvents = await fetchEvents({
        lastEventId: events[events.length - 1]?.id,
      });
      setEvents((prev) => [...prev, ...nextEvents]);
      if (nextEvents.length < PAGE_SIZE_ORGANIZATION_EVENTS) {
        setMoreToLoad(false);
      }
    }
  };

  if (!organization) return null;

  const showWeekDaySelectionWidget = orgFutureEventCount >= 5;

  return (
    <Panel background="rgba(255, 255, 255, 0.59)">
      {showWeekDaySelectionWidget && (
        <>
          <WeekCalendar
            onNext={handleNextWeek}
            onPrevious={handlePreviousWeek}
            onDaySelected={handleDaySelected}
            eventsByDay={eventsByDay}
            selectedDate={selectedDate}
            startDate={getMondayOfCurrentWeek()}
          />
          <Divider borderWidth="1px" color="rgba(0, 0, 0, 0.1)" mb={"0.75em"} />
        </>
      )}

      <EventCalendarList groupedEvents={eventsByDayFromSelectedDate} />

      {!loading && moreToLoad && <SkeletonLoader onLoadMore={handleLoadMore} />}

      {!loading && (events.length === 0 || !moreToLoad) && (
        <EventCalendarEmptyState />
      )}
    </Panel>
  );
};
