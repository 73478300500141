import { Box, BoxProps } from "@chakra-ui/react";
import { NavItem } from "./NavItem";
import { IconType } from "react-icons";
import { Panel } from "../../../../components/Panel";

export interface SidebarLinkItem {
  name: string;
  icon: IconType;
  to: string;
}

interface SidebarProps extends BoxProps {
  linkItems: SidebarLinkItem[];
}

export const SidebarContent = ({ linkItems, ...rest }: SidebarProps) => {
  return (
    <Box h="100%" {...rest}>
      <Panel>
        {linkItems.map((link) => (
          <NavItem key={link.name} icon={link.icon} to={link.to}>
            {link.name}
          </NavItem>
        ))}
      </Panel>
    </Box>
  );
};
