import axiosInstance from "./axiosInstance";

export interface EstimateFeesAndTaxesResponse {
  taxes_in_cents: number;
  fees_in_cents: number;
  include_fees_in_prices: boolean;
  include_taxes_in_prices: boolean;
}

export const estimateFeesAndTaxes = async (
  priceInCents: number,
  numTaxBasisPoints: number,
  currencyIsoCode: string,
  includeFeesInPrices: boolean,
  includeTaxesInPrices: boolean
): Promise<EstimateFeesAndTaxesResponse> => {
  const response = await axiosInstance.get("/fees/estimate_fees_and_taxes", {
    params: {
      priceInCents,
      numTaxBasisPoints,
      currencyIsoCode,
      includeFeesInPrices,
      includeTaxesInPrices,
    },
  });
  return response.data;
};
