import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  Spacer,
  Text,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { FieldError } from "react-hook-form";
import { TEXT_SECONDARY_COLOR } from "../../services/theme/colors";
import { inputDefaultThemeProps } from "../../services/theme/overrides/input";

interface StyledInputProps {
  name: string;
  label?: string;
  error?: FieldError;
  type?: string;
  isRequired?: boolean;
  placeholder?: string;
  inputProps?: InputProps;
  leftElement?: ReactNode;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
}

export const StyledInput: React.FC<StyledInputProps> = ({
  name,
  label,
  error,
  type,
  isRequired,
  placeholder,
  inputProps,
  leftElement,
  onChange,
  value,
}) => {
  return (
    <FormControl isInvalid={!!error} width="100%" marginTop={0}>
      {(label || !isRequired) && type !== "hidden" && (
        <HStack align="center" marginBottom={2}>
          {label && (
            <FormLabel htmlFor={name} marginBottom={0}>
              <Text size="md">{label}</Text>
            </FormLabel>
          )}
          <Spacer />
          {!isRequired && (
            <Text size="md" color={TEXT_SECONDARY_COLOR}>
              Optional
            </Text>
          )}
        </HStack>
      )}
      <InputGroup marginTop={0}>
        {leftElement && <InputLeftElement>{leftElement}</InputLeftElement>}
        <Input
          {...inputDefaultThemeProps}
          onChange={onChange}
          onWheel={(e) => type === "number" && e.currentTarget.blur()}
          type={type || "text"}
          placeholder={placeholder}
          value={value}
          {...inputProps}
        />
      </InputGroup>
      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};
