import { useParams, useSearchParams } from "react-router-dom";
import { useVianikoUser } from "../../hooks/useVianikoUser";
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import { SocialButtons } from "../../components/social/Buttons";
import { useCurrentUser } from "../../providers/CurrentUserProvider";
import { useFriendship } from "../../hooks/useFriendship";
import { RemoveFriendButton } from "../../components/RemoveFriendButton";
import { Panel } from "../../components/Panel";
import { ButtonLink } from "../../components/ButtonLink";
import { userEditUrl } from "../../services/routes/urlBuilder";
import { useFriendships } from "../../hooks/useFriendships";
import { UserFriendsList } from "./components/UserFriendsList";
import { useFriendshipRequests } from "../events/hooks/useFriendshipRequests";
import { useEffect, useState } from "react";
import { useOrganizations } from "../../hooks/useOrganizations";
import { OrganizationListThemeCard } from "../organizations/components/OrganizationListThemeCard";
import { UserAvatar } from "../../components/UserAvatar";
import { EventsList } from "./components/EventsList";

export const UserShowPage: React.FC = () => {
  const { id } = useParams();
  const user = useVianikoUser(id);

  const { currentUser } = useCurrentUser();
  const { friendship, refetch: refetchFriendship } = useFriendship(id);
  const { friends, refetch: refetchAllFriendships } = useFriendships(id);
  const {
    create: createFriendshipRequest,
    friendshipRequestIncomingByUserId,
    friendshipRequestOutgoingByUserId,
  } = useFriendshipRequests();
  const { organizations } = useOrganizations(id);
  const [tabIndex, setTabIndex] = useState(0);
  const [searchParams] = useSearchParams();
  const initialTab = searchParams.get("tab");

  const friendsTabIndex = (id === currentUser?.id || friendship) ? 2 : 1;

  useEffect(() => {
    if (initialTab === "friends") {
      setTabIndex(friendsTabIndex);
    } else if (initialTab === "events") {
      setTabIndex(1);
    }
  }, [initialTab, friendshipRequestIncomingByUserId, id, currentUser, friendship, friendsTabIndex]);

  if (!id || !user || !currentUser) return null;

  const handleAddFriend = async () => {
    await createFriendshipRequest(user.id);
  };

  const handleRemoveFriend = () => {
    refetchFriendship();
  };

  const hasSocial = user.instagram_handle && user.instagram_handle !== "";

  return (
    <>
      <Panel padding={0}>
        <Flex>
          <Box width="50%">
            <UserAvatar user={user} size="2xl" />
          </Box>
          <Box width="50%" padding={5}>
            <Heading size="xl">{user.full_name}</Heading>
          </Box>
        </Flex>
      </Panel>

      {id === currentUser.id ? (
        <Flex gap={1} width="100%" flexWrap="wrap">
          <ButtonLink
            to={userEditUrl(user.id)}
            buttonProps={{ variant: "primary", size: "lg", width: "100%" }}
            flexGrow={1}
          >
            Edit profile
          </ButtonLink>
        </Flex>
      ) : friendship && friendship.status === "active" ? (
        <HStack gap={1}>
          <Button variant="primary" isDisabled flexGrow={1}>
            Friends
          </Button>
          <RemoveFriendButton
            friendshipId={friendship.id}
            userId={currentUser.id}
            onRemove={handleRemoveFriend}
          />
        </HStack>
      ) : friendshipRequestOutgoingByUserId?.[id] ? (
        <Button variant="primary" isDisabled width="100%">
          Friendship pending
        </Button>
      ) : (
        <Button variant="primary" width="100%" onClick={handleAddFriend}>
          Add friend
        </Button>
      )}

      <Tabs index={tabIndex}>
        <TabList>
          <Tab onClick={() => setTabIndex(0)}>About</Tab>
          {(id === currentUser?.id || friendship) && (
            <Tab onClick={() => setTabIndex(1)}>Calendar</Tab>
          )}
          <Tab onClick={() => setTabIndex(friendsTabIndex)}>Friends</Tab>
          {id === currentUser?.id && (
            <Tab onClick={() => setTabIndex(friendsTabIndex + 1)}>
              {organizations && organizations.length} communities
            </Tab>
          )}
        </TabList>

        <TabPanels>
          <TabPanel>
            <Panel>
              <Text>{user.description}</Text>
              {hasSocial && (
                <Box marginTop={6}>
                  <SocialButtons
                    instagramHandle={user.instagram_handle}
                    twitterHandle=""
                    linkedinUrl=""
                  />
                </Box>
              )}
            </Panel>
          </TabPanel>

          {(id === currentUser?.id || friendship) && (
            <TabPanel>
              <EventsList userId={id} />
            </TabPanel>
          )}

          <TabPanel>
            <UserFriendsList
              userId={user.id}
              friends={friends}
              onChange={refetchAllFriendships}
            />
          </TabPanel>


          {id === currentUser?.id && (
            <TabPanel>
              <VStack width="100%" gap={1}>
                {organizations?.map((org) => (
                  <OrganizationListThemeCard key={org.id} organization={org} />
                ))}
              </VStack>
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </>
  );
};
