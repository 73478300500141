import { useMemo } from "react";
import { addDays, addWeeks } from "date-fns";

interface CalendarDay {
  date: Date;
  isToday: boolean;
}

export const useCalendarFromToday = (weekOffset: number = 0): CalendarDay[] => {
  return useMemo(() => {
    const today = new Date();
    const startDate = addWeeks(today, weekOffset);

    // Create array of 7 days starting from the offset date
    return Array.from({ length: 7 }, (_, index) => {
      const date = addDays(startDate, index);
      return {
        date,
        isToday:
          date.getDate() === today.getDate() &&
          date.getMonth() === today.getMonth() &&
          date.getFullYear() === today.getFullYear(),
      };
    });
  }, [weekOffset]);
};


export const useCalendarFromStartingDate = (startDate: Date, weekOffset: number = 0): CalendarDay[] => {
  return useMemo(() => {
    const today = new Date();
    const startDateWithWeekOffset = addWeeks(startDate, weekOffset);

    // Create array of 7 days starting from the offset date
    return Array.from({ length: 7 }, (_, index) => {
      const date = addDays(startDateWithWeekOffset, index);
      return {
        date,
        isToday:
          date.getDate() === today.getDate() &&
          date.getMonth() === today.getMonth() &&
          date.getFullYear() === today.getFullYear(),
      };
    });
  }, [weekOffset, startDate]);
};