import {
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { NumberField } from "../../../../components/forms/NumberField";
import { Organization } from "../../../../types/organization";
import { Control, useController } from "react-hook-form";

interface RefundsFormSectionProps {
  control: Control<Organization>;
}

export const RefundsFormSection: React.FC<RefundsFormSectionProps> = ({
  control,
}) => {
  const {
    field: { value: isRefundable, onChange: onChangeRefundable },
  } = useController({
    name: "is_refundable",
    control,
  });

  const {
    field: { value: numDaysRefundableAhead, onChange: onChangeNumDays },
  } = useController({
    name: "num_days_refundable_ahead",
    control,
  });

  const handleChangeLastDay = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeNumDays(e.target.checked ? 1 : 0);
  };

  return (
    <VStack alignItems="stretch" gap={2}>
      <Heading size="lg" as="h2" marginBottom={2}>
        Refunds
      </Heading>
      <FormControl>
        <HStack>
          <Checkbox
            isChecked={isRefundable}
            onChange={(e) => onChangeRefundable(e.target.checked)}
          />
          <FormLabel margin={0}>allow refunds</FormLabel>
        </HStack>
      </FormControl>

      {isRefundable && (
        <>
          <FormControl>
            <HStack>
              <Checkbox
                isChecked={numDaysRefundableAhead > 0}
                onChange={handleChangeLastDay}
              />
              <FormLabel margin={0}>last day for refunds</FormLabel>
            </HStack>
          </FormControl>

          {numDaysRefundableAhead > 0 && (
            <FormControl>
              <HStack>
                <NumberField
                  value={numDaysRefundableAhead}
                  onChange={onChangeNumDays}
                />
                <FormLabel margin={0}>
                  days before the start of each event
                </FormLabel>
              </HStack>
            </FormControl>
          )}
        </>
      )}
    </VStack>
  );
};
