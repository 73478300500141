import {
  Input,
  InputGroup,
  InputRightAddon,
  InputProps,
} from "@chakra-ui/react";
import { Control, useController, Path, FieldValues } from "react-hook-form";
import {
  percentInputDefaultThemeProps,
  percentInputGroupDefaultThemeProps,
} from "../../services/theme/overrides/input";
import { useState } from "react";

const PERCENT_PATTERN = "^[0-9]+([.][0-9]*)?$";
const PERCENT_REGEX = new RegExp(PERCENT_PATTERN);

const formatPercentageForDisplay = (basisPoints: number | null) => {
  if (basisPoints === null) {
    return "";
  }
  // Round to avoid floating point precision issues
  const percentValue = Math.round(basisPoints) / 100;
  return percentValue.toFixed(2).replace(/\.?0+$/, "");
};

const formatPercentageForEdit = (basisPoints: number | null) => {
  if (basisPoints === null) {
    return "";
  }
  // Round to avoid floating point precision issues
  const percentValue = Math.round(basisPoints) / 100;
  return percentValue.toFixed(2);
};

interface PercentInputProps<T extends FieldValues> extends InputProps {
  name: Path<T>;
  control: Control<T>;
  size?: "sm" | "md" | "lg";
}

export const PercentInput = <T extends FieldValues>({
  name,
  control,
  size = "sm",
  ...props
}: PercentInputProps<T>): React.ReactElement => {
  const {
    field: { onChange, value },
  } = useController<T>({
    name,
    control,
  });

  const [localValue, setLocalValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (newValue === "" || PERCENT_REGEX.test(newValue)) {
      setLocalValue(newValue);
    }
  };

  const handleBlur = () => {
    setIsFocused(false);
    if (localValue === "") {
      onChange(null);
      return;
    }

    const newValue = Math.round(parseFloat(localValue) * 100);
    onChange(newValue);
  };

  const handleFocus = () => {
    setIsFocused(true);
    setLocalValue(formatPercentageForEdit(value));
  };

  const displayValue = isFocused
    ? localValue
    : formatPercentageForDisplay(value);

  return (
    <InputGroup
      width="120px"
      size={size}
      {...percentInputGroupDefaultThemeProps}
    >
      <Input
        {...percentInputDefaultThemeProps}
        {...props}
        size={size}
        value={displayValue}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder="0.00"
        type="text"
        pattern={PERCENT_PATTERN}
        inputMode="decimal"
        maxLength={6}
      />
      <InputRightAddon
        backgroundColor="transparent"
        border="none"
        color="gray.500"
      >
        %
      </InputRightAddon>
    </InputGroup>
  );
};
