import { ButtonLink } from "../../../components/ButtonLink";
import { waiverNewUrl } from "../../../services/routes/urlBuilder";
import { Panel } from "../../../components/Panel";
import { useCurrentOrganizationUser } from "../../../providers/CurrentOrganizationUserProvider";
import { useWaivers } from "../../../hooks/useWaivers";
import { WaiverItem } from "./WaiverItem";
import { Outlet } from "react-router-dom";
import { useCurrentOrganization } from "../../../providers/CurrentOrganizationProvider";
import { useMemo } from "react";
import { Text, VStack } from "@chakra-ui/react";
import {
  hasPermission,
  PERMISSION_WAIVERS,
} from "../../../services/permissions";

export const WaiversList: React.FC = () => {
  const { organization } = useCurrentOrganization();
  const { currentOrganizationUser } = useCurrentOrganizationUser();

  const { waivers, refetch } = useWaivers(organization?.id);

  const handleDelete = async () => {
    await refetch();
  };

  const deletedWaivers = useMemo(() => {
    return waivers?.filter((waiver) => waiver.deleted_at);
  }, [waivers]);

  const activeWaivers = useMemo(() => {
    return waivers?.filter((waiver) => !waiver.deleted_at);
  }, [waivers]);

  const canManageWaivers =
    currentOrganizationUser &&
    (currentOrganizationUser.is_owner ||
      hasPermission(currentOrganizationUser.permissions, PERMISSION_WAIVERS));

  if (!organization) return null;

  return (
    <>
      <Panel>
        <VStack align="stretch" gap={3}>
          {activeWaivers?.map((waiver) => (
            <WaiverItem
              key={waiver.id}
              waiver={waiver}
              onDelete={handleDelete}
              canManage={!!canManageWaivers}
            />
          ))}
        </VStack>
        {canManageWaivers && (
          <ButtonLink
            to={waiverNewUrl(organization.slug)}
            buttonProps={{
              size: "lg",
              variant: "outline",
              width: "100%",
              marginTop: 4,
            }}
          >
            Add Waiver
          </ButtonLink>
        )}

        {canManageWaivers && (
          <Text size="md" marginTop={4}>
            Past waivers
          </Text>
        )}

        <VStack align="stretch" gap={3}>
          {deletedWaivers?.map((waiver) => (
            <WaiverItem
              key={waiver.id}
              waiver={waiver}
              onDelete={handleDelete}
              canManage={!!canManageWaivers}
            />
          ))}
        </VStack>
      </Panel>

      <Outlet />
    </>
  );
};
