import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Waiver } from "../../types/waiver";
import { fetchWaiver } from "../../services/api/waivers";
import { organizationWaiversUrl } from "../../services/routes/urlBuilder";
import { SignWaiverModal } from "./SignWaiverModal";
import { useWaiverSignature } from "../../hooks/useWaiverSignature";
import { useCurrentOrganization } from "../../providers/CurrentOrganizationProvider";

export const ShowWaiver: React.FC = () => {
  const [waiver, setWaiver] = useState<Waiver>();
  const [searchParams] = useSearchParams();
  const { waiverId } = useParams();
  const { organization } = useCurrentOrganization();
  const redirectTo = searchParams.get("redirectTo");
  const navigate = useNavigate();
  const { waiverSignature } = useWaiverSignature(waiver);

  useEffect(() => {
    const fetch = async () => {
      if (!organization || !waiverId) return;
      const result = await fetchWaiver(organization.id, waiverId);
      setWaiver(result);
    };

    fetch();
  }, [organization, waiverId]);

  const handleRedirect = () => {
    if (redirectTo) {
      navigate(redirectTo);
    } else if (organization) {
      window.location.href = organizationWaiversUrl(organization.slug);
    }
  };

  const handleClose = () => {
    if (organization) {
      navigate(organizationWaiversUrl(organization.slug));
    }
  };

  const handleComplete = async () => {
    handleRedirect();
  };

  if (!waiver) return null;

  return (
    <SignWaiverModal
      waiver={waiver}
      waiverSignature={waiverSignature}
      isOpen={true}
      onClose={handleClose}
      onComplete={handleComplete}
    />
  );
};
