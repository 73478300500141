import { addDays, isToday } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

export const formatDateUpcomingEvent = (
  date: Date,
  iana_timezone: string
): string => {
  const zonedDate = new Date(
    date.toLocaleString("en-US", { timeZone: iana_timezone })
  );
  const now = new Date(
    new Date().toLocaleString("en-US", { timeZone: iana_timezone })
  );

  if (isToday(zonedDate)) {
    return "Today";
  } else if (zonedDate < addDays(now, 6)) {
    return formatInTimeZone(date, iana_timezone, "EEEE");
  } else {
    return formatInTimeZone(date, iana_timezone, "MMM d");
  }
};

export const formatDatePastEvent = (
  date: Date,
  iana_timezone: string
): string => {
  return formatInTimeZone(date, iana_timezone, "MMM d");
};

export const formatDateOrganizationNextEvent = (
  date: Date,
  iana_timezone: string
): string => {
  const zonedDate = new Date(
    date.toLocaleString("en-US", { timeZone: iana_timezone })
  );
  if (isToday(zonedDate)) {
    return "Today";
  } else if (zonedDate < addDays(new Date(), 6)) {
    return formatInTimeZone(date, iana_timezone, "EEEE");
  } else if (zonedDate < addDays(new Date(), 14)) {
    return "Next week";
  } else {
    return formatInTimeZone(date, iana_timezone, "MMM d");
  }
};
