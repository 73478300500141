import { Heading, HStack, Spacer, VStack } from "@chakra-ui/react";
import { useMemo } from "react";
import { Panel } from "../../../components/Panel";
import { useCurrentOrganization } from "../../../providers/CurrentOrganizationProvider";
import { useCurrentOrganizationUser } from "../../../providers/CurrentOrganizationUserProvider";
import {
  hasPermission,
  PERMISSION_MANAGE_PASSES,
} from "../../../services/permissions";
import { usePasses } from "../../passes/hooks/usePasses";
import { PassInfo } from "./PassInfo";
import { OrganizationSetupPaymentsBadge } from "../../payments/components/OrganizationSetupPaymentsBadge";
import { ButtonLink } from "../../../components/ButtonLink";
import { passNewUrl } from "../../../services/routes/urlBuilder";
import { useOutletContext } from "react-router-dom";
import { AdminPassInfo } from "./AdminPassInfo";

export const PassesTab: React.FC = () => {
  const { showPasses } = useOutletContext<any>();

  const { organization } = useCurrentOrganization();
  const { currentOrganizationUser } = useCurrentOrganizationUser();
  const { passes, refetchPasses } = usePasses(organization?.id);

  const canManagePasses = useMemo(() => {
    if (!currentOrganizationUser) return false;

    return (
      currentOrganizationUser.is_owner ||
      hasPermission(
        currentOrganizationUser.permissions,
        PERMISSION_MANAGE_PASSES
      )
    );
  }, [currentOrganizationUser]);

  const handleDelete = () => {
    refetchPasses();
  };

  if (!showPasses || !organization) return null;

  return (
    <VStack alignItems="stretch">
      {canManagePasses && (
        <HStack>
          <Spacer />
          <OrganizationSetupPaymentsBadge
            organization={organization}
            canConnectStripe={canManagePasses}
          />
        </HStack>
      )}

      <Panel>
        <VStack alignItems="stretch">
          {canManagePasses && (
            <>
              <Heading as="h2" size="xl">
                Organization passes
              </Heading>
              <VStack alignItems="stretch" gap={2}>
                {passes.map((pass) => (
                  <AdminPassInfo
                    key={pass.id}
                    pass={pass}
                    organization={organization}
                    onDelete={handleDelete}
                  />
                ))}
              </VStack>

              {canManagePasses && (
                <ButtonLink
                  to={passNewUrl(organization.slug)}
                  buttonProps={{
                    variant: "primary",
                    size: "lg",
                    width: "100%",
                    isDisabled: !organization.stripe_account_id,
                  }}
                  marginY={2}
                >
                  Create pass
                </ButtonLink>
              )}

              <Heading as="h2" size="xl" marginTop={4}>
                Your passes
              </Heading>
            </>
          )}

          <VStack alignItems="stretch" gap={2}>
            {passes.map((pass) => (
              <PassInfo key={pass.id} pass={pass} organization={organization} />
            ))}
          </VStack>
        </VStack>
      </Panel>
    </VStack>
  );
};
