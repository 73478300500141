import { useEffect, useState } from "react";
import { WaiverSignature } from "../types/waiver_signature";
import { fetchWaiverSignature } from "../services/api/waivers";
import { Waiver } from "../types/waiver";

export const useWaiverSignature = (waiver: Waiver | undefined) => {
  const [waiverSignature, setWaiverSignature] = useState<WaiverSignature>();

  useEffect(() => {
    const fetch = async () => {
      if (!waiver) return;

      const result = await fetchWaiverSignature(
        waiver.organization_id,
        waiver.id
      );
      setWaiverSignature(result);
    };

    fetch();
  }, [waiver]);

  return { waiverSignature };
};
