import { Badge } from "@chakra-ui/react";
import { ButtonLink } from "./ButtonLink";

interface AdminActionBadgeProps {
  alertText?: string;
  actionText: string;
  actionUrl: string;
  hideAction?: boolean;
}
export const AdminActionBadge: React.FC<AdminActionBadgeProps> = ({
  alertText,
  actionText,
  actionUrl,
  hideAction,
}) => {
  return (
    <>
      <Badge colorScheme="red">{alertText}</Badge>
      {!hideAction && (
        <ButtonLink
          to={actionUrl}
          buttonProps={{
            variant: "ghost",
            size: "sm",
          }}
        >
          {actionText}
        </ButtonLink>
      )}
    </>
  );
};
