import { useForm } from "react-hook-form";
import { Pass } from "../../../types/passes";
import { Panel } from "../../../components/Panel";
import { StyledTextarea } from "../../../components/forms/StyledTextarea";
import { StyledControlledInput } from "../../../components/forms/StyledControlledInput";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  HStack,
  Switch,
  Text,
  VStack,
  Box,
} from "@chakra-ui/react";
import { DollarsInput } from "../../../components/forms/DollarsInput";
import { useParams } from "react-router-dom";
import { useRecurringEvents } from "../../../hooks/useRecurringEvents";
import { PassConfigurationSection } from "./PassConfigurationSection";
import { MembershipConfigurationSection } from "./MembershipConfigurationSection";
import { VianikoEvent } from "../../../types/events";
import { useEffect, useMemo, useState } from "react";
import { fetchNonRecurringEvents } from "../../../services/api/organizations";
import { useCurrentUser } from "../../../providers/CurrentUserProvider";
import { useOrganization } from "../../../providers/CurrentOrganizationProvider";
import { CURRENCY_CODE_TO_CURRENCY } from "../../../services/currencies";
import { EstimatedFeesAndTaxesIndicator } from "../../../components/FeesAndTaxesIndicator";
import { OverrideTaxesAndFeesFormSection } from "../../../pages/fees/OverrideTaxesAndFeesFormSection";

interface PassFormProps {
  onSubmit: (data: Pass) => void;
  pass?: Pass;
}

export const PassForm: React.FC<PassFormProps> = ({ onSubmit, pass }) => {
  const { organizationId } = useParams();
  const { organization } = useOrganization(organizationId);
  const { currentUser } = useCurrentUser();
  const { recurringEvents } = useRecurringEvents(organization?.id);
  const [nonRecurringEvents, setNonRecurringEvents] = useState<
    VianikoEvent[] | undefined
  >(undefined);

  useEffect(() => {
    const init = async () => {
      if (!organization || !currentUser) return;

      const events = await fetchNonRecurringEvents(organization.id);

      setNonRecurringEvents(events);
    };

    init();
  }, [organization, currentUser]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    watch,
  } = useForm<Pass>({
    mode: "onBlur",
    defaultValues: pass ?? {
      overrides_fees_and_taxes: false,
      include_fees_in_prices: organization?.include_fees_in_prices,
      include_taxes_in_prices: organization?.include_taxes_in_prices,
      num_tax_basis_points: organization?.num_tax_basis_points,
    },
  });

  const passId = watch("id");
  const eventAndRecurringEventIds = watch("event_ids");
  const isSubscription = watch("is_subscription");

  const eventsAndRecurringEvents = useMemo(() => {
    return recurringEvents && nonRecurringEvents
      ? [...recurringEvents, ...nonRecurringEvents]
      : undefined;
  }, [nonRecurringEvents, recurringEvents]);

  useEffect(() => {
    if (!passId && eventsAndRecurringEvents && setValue) {
      setValue(
        "event_ids",
        eventsAndRecurringEvents.map((e) => e.id)
      );
    }
  }, [passId, setValue, eventsAndRecurringEvents]);

  const handleChangeValidFor = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setValue("event_ids", [
        ...(getValues("event_ids") || []),
        e.target.value,
      ]);
    } else {
      setValue(
        "event_ids",
        (getValues("event_ids") || []).filter((id) => id !== e.target.value)
      );
    }
  };

  if (!organization || !recurringEvents || !eventAndRecurringEventIds)
    return null;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Panel>
        <VStack gap={4}>
          <StyledControlledInput
            name="name"
            control={control}
            label="Name"
            type="text"
            rules={{ required: "This field is required" }}
          />
          <StyledTextarea
            name="description"
            register={register}
            label="Details"
            error={errors.description}
          />
          <HStack width="100%" align="end">
            <DollarsInput
              name="price_in_cents"
              label="Price"
              control={control}
              currencyIsoCode={organization?.currency_iso_code}
              min={
                CURRENCY_CODE_TO_CURRENCY[
                  organization?.currency_iso_code ?? "USD"
                ].minPriceInCents
              }
              width="164px"
            />
            <Box height={7}>
              <EstimatedFeesAndTaxesIndicator
                currencyIsoCode={organization?.currency_iso_code ?? "USD"}
                priceInCents={watch("price_in_cents")}
                numTaxBasisPoints={watch("num_tax_basis_points")}
                includeFeesInPrices={watch("include_fees_in_prices")}
                includeTaxesInPrices={watch("include_taxes_in_prices")}
              />
            </Box>
          </HStack>
          <OverrideTaxesAndFeesFormSection<Pass>
            currencyIsoCode={organization?.currency_iso_code ?? "USD"}
            control={control}
            organization={organization}
          />
        </VStack>
      </Panel>

      <Panel>
        <FormControl marginBottom={4}>
          <HStack gap={2}>
            <FormLabel margin={0}>Pass</FormLabel>

            <Switch isDisabled={!!passId} {...register("is_subscription")} />

            <FormLabel margin={0}>Membership</FormLabel>
          </HStack>
        </FormControl>

        {isSubscription ? (
          <MembershipConfigurationSection passId={passId} control={control} />
        ) : (
          <PassConfigurationSection passId={passId} control={control} />
        )}
      </Panel>

      <Panel>
        <VStack alignItems="stretch">
          <Text>Valid for</Text>
          <CheckboxGroup defaultValue={eventAndRecurringEventIds}>
            {eventsAndRecurringEvents?.map((event) => (
              <Checkbox
                key={event.id}
                value={event.id}
                onChange={handleChangeValidFor}
              >
                {event.name}
              </Checkbox>
            ))}
          </CheckboxGroup>
        </VStack>
      </Panel>

      <Button
        type="submit"
        variant="primary"
        size="lg"
        width="100%"
        marginTop={6}
        marginBottom={12}
      >
        Save
      </Button>
    </form>
  );
};
