import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { Panel } from "../../../components/Panel";
import { Ticket } from "../../../types/ticket";
import { usePass } from "../../passes/hooks/usePass";
import { VianikoEvent } from "../../../types/events";
import { useTicketPricePaid } from "../hooks/useTicketPricePaid";
import { priceInDollars } from "../../../services/payments";

interface CancelTicketConfirmationModalProps {
  ticket: Ticket;
  event: VianikoEvent;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (ticket: Ticket) => void;
}

export const CancelTicketConfirmationModal: React.FC<
  CancelTicketConfirmationModalProps
> = ({ ticket, event, isOpen, onClose, onConfirm }) => {
  const { pass } = usePass(ticket.pass_id);
  const { price } = useTicketPricePaid(ticket);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Cancel ticket</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Panel>
            <>
              <Text>
                You’re canceling your ticket.{" "}
                {pass && !pass.is_unlimited
                  ? `1 credit will be returned to your ${pass.name ?? "pass"}.`
                  : price && price > 0
                  ? `${priceInDollars(
                      event.currency_iso_code,
                      price
                    )} will be refunded to your original payment method.`
                  : ""}
              </Text>
              <Flex justifyContent="center" marginTop={4}>
                <Button onClick={onClose} variant="unstyled">
                  Keep ticket
                </Button>
                <Button onClick={() => onConfirm(ticket)} colorScheme="red">
                  Cancel ticket
                </Button>
              </Flex>
            </>
          </Panel>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
