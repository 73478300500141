import React, { createContext, useContext, useState, useEffect } from "react";
import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";
import { Auth, getAuth } from "firebase/auth";

interface FirebaseAppContextType {
  app: FirebaseApp;
  auth: Auth;
}

interface FirebaseAppProviderProps {
  children: React.ReactNode;
}

const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyCYEVvxHgF4iPdjAQmBe6_CxxC4bh2kpOk",
  authDomain: "vianiko-app.firebaseapp.com",
  projectId: "vianiko-app",
  storageBucket: "vianiko-app.appspot.com",
  messagingSenderId: "983888754319",
  appId: "1:983888754319:web:448a264fb15580890aa2fa",
  measurementId: "G-V97CXKGPZK",
};

export const FirebaseAppContext = createContext<
  FirebaseAppContextType | undefined
>(undefined);

export const useFirebaseApp = () => {
  return useContext(FirebaseAppContext);
};

export function FirebaseAppProvider({
  children,
}: FirebaseAppProviderProps): React.ReactElement | null {
  const [firebaseApp, setFirebaseApp] = useState<FirebaseApp | null>(null);
  const [auth, setAuth] = useState<Auth | null>(null);

  useEffect(() => {
    const app = initializeApp(firebaseConfig);

    setFirebaseApp(app);
  }, []);

  useEffect(() => {
    const auth = getAuth();
    setAuth(auth);
  }, []);

  if (!firebaseApp || !auth) {
    return null;
  }

  return (
    <FirebaseAppContext.Provider value={{ app: firebaseApp, auth }}>
      {children}
    </FirebaseAppContext.Provider>
  );
}
