export type OrganizationTheme =
  | "health"
  | "nature"
  | "nightlife"
  | "sports"
  | "corporate"
  | "corporate-dark";

export const organizationThemes: OrganizationTheme[] = [
  "health",
  "nature",
  "nightlife",
  "sports",
  "corporate",
  "corporate-dark",
];

export interface Organization {
  id: string;
  name: string;
  description: string;
  theme: OrganizationTheme;
  created_at: string;
  updated_at: string;
  is_refundable: boolean;
  num_days_refundable_ahead: number;
  include_fees_in_prices: boolean;
  include_taxes_in_prices: boolean;
  num_tax_basis_points: number;
  currency_iso_code?: string;
  photo_ids?: string[];
  photo_id?: string;
  stripe_account_id?: string;
  slug: string;
}
