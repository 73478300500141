import {
  InputGroupProps,
  InputProps,
  NumberInputFieldProps,
  SelectFieldProps,
} from "@chakra-ui/react";

export const inputDefaultThemeProps: InputProps = {
  backgroundColor: "white",
  border: "2px solid",
  _focusVisible: { border: "2px dashed black" },
};

export const numberInputDefaultThemeProps: NumberInputFieldProps = {
  backgroundColor: "white",
  border: "2px solid",
  borderRadius: "24px",
};

export const percentInputGroupDefaultThemeProps: InputGroupProps = {
  backgroundColor: "white",
  border: "2px solid",
  borderRadius: "24px",
  _focusVisible: { border: "2px dashed black" },
};

export const percentInputDefaultThemeProps: InputProps = {
  border: "none",
  _focusVisible: { border: "none" },
};

export const selectDefaultThemeProps: SelectFieldProps = {
  backgroundColor: "white",
  border: "2px solid",
  borderRadius: "24px",
};
