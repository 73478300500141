import { useEffect, useState } from "react";
import { RecurringEvent } from "../../types/recurring_events";
import * as api from "../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import { organizationShowUrl } from "../../services/routes/urlBuilder";
import { useCurrentUser } from "../../providers/CurrentUserProvider";
import { useOrganization } from "../../providers/CurrentOrganizationProvider";
import { EventForm } from "../events/components/forms/EventForm";
import { VianikoEvent } from "../../types/events";

export const EditRecurringEvent: React.FC = () => {
  const [recurringEvent, setRecurringEvent] = useState<RecurringEvent>();
  const { id } = useParams();
  const { currentUser } = useCurrentUser();
  const { organization } = useOrganization(recurringEvent?.organization_id);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRecurringEvent = async () => {
      if (!id || !currentUser) return;

      const recurringEvent = await api.recurringEvents.fetchRecurringEvent(id);
      setRecurringEvent(recurringEvent);
    };

    fetchRecurringEvent();
  }, [id, currentUser]);

  const handleSubmit = async (
    data: VianikoEvent & RecurringEvent,
    dirtyFields?: any
  ) => {
    if (!id) return;

    const changedData = Object.keys(dirtyFields).reduce(
      (acc: any, key: any) => {
        acc[key] = data[key as keyof RecurringEvent];
        return acc;
      },
      {} as Partial<RecurringEvent>
    );

    await api.recurringEvents.updateRecurringEvent(id, changedData);
    navigate(organizationShowUrl(organization?.slug || ""));
  };

  if (!recurringEvent || !id) return null;

  return <EventForm onSubmit={handleSubmit} defaultValues={recurringEvent} />;
};
