import { DEFAULT_CURRENCY_ISO_CODE } from "./payments";

interface Currency {
  code: string;
  symbol: string;
  minPriceInCents: number;
}

const USD: Currency = {
  code: "USD",
  symbol: "$",
  minPriceInCents: 100,
};

const AUD: Currency = {
  code: "AUD",
  symbol: "A$",
  minPriceInCents: 100,
};

const CAD: Currency = {
  code: "CAD",
  symbol: "C$",
  minPriceInCents: 100,
};

const MEX: Currency = {
  code: "MXN",
  symbol: "MX$",
  minPriceInCents: 5000,
};

export const CURRENCIES: Currency[] = [USD, AUD, CAD, MEX];

// todo move all calls to CURRENCY_CODE_TO_CURRENCY to use getCurrencyByCode instead
//    remove export CURRENCY_CODE_TO_CURRENCY
export const CURRENCY_CODE_TO_CURRENCY: { [code: string]: Currency } =
  CURRENCIES.reduce((acc, currency) => {
    acc[currency.code] = currency;
    return acc;
  }, {} as { [code: string]: Currency });

export const getCurrencyByCode = (code: string): Currency => {
  return (
    CURRENCY_CODE_TO_CURRENCY[code] ??
    CURRENCY_CODE_TO_CURRENCY[DEFAULT_CURRENCY_ISO_CODE]
  );
};
