import { Text } from "@chakra-ui/react";
import { priceInDollars } from "../services/payments";
import { TEXT_SECONDARY_COLOR } from "../services/theme/colors";
import { useEffect, useState } from "react";
import {
  estimateFeesAndTaxes,
  EstimateFeesAndTaxesResponse,
} from "../services/api/fees";
import { useDebounce } from "use-debounce";

interface FeesAndTaxesIndicatorProps {
  currencyIsoCode: string;
  feesInCents?: number;
  taxesInCents?: number;
  includeFeesInPrices: boolean | undefined;
  includeTaxesInPrices: boolean | undefined;
}

export const FeesAndTaxesIndicator: React.FC<FeesAndTaxesIndicatorProps> = ({
  currencyIsoCode,
  feesInCents,
  taxesInCents,
  includeFeesInPrices,
  includeTaxesInPrices,
}) => {
  const taxesAndFees =
    (includeFeesInPrices ? 0 : feesInCents ?? 0) +
    (includeTaxesInPrices ? 0 : taxesInCents ?? 0);

  if (taxesAndFees === 0) {
    return null;
  }

  let feesTaxesText;
  if (!includeFeesInPrices && !includeTaxesInPrices) {
    feesTaxesText = "fees and taxes";
  } else if (!includeFeesInPrices) {
    feesTaxesText = "fees";
  } else if (!includeTaxesInPrices) {
    feesTaxesText = "taxes";
  }

  return (
    <Text size="sm" color={TEXT_SECONDARY_COLOR} marginBottom={0}>
      + {priceInDollars(currencyIsoCode, taxesAndFees)} {feesTaxesText}
    </Text>
  );
};

interface EstimatedFeesAndTaxesIndicatorProps {
  currencyIsoCode: string;
  priceInCents: number;
  numTaxBasisPoints: number;
  includeFeesInPrices: boolean | undefined;
  includeTaxesInPrices: boolean | undefined;
}

export const EstimatedFeesAndTaxesIndicator: React.FC<
  EstimatedFeesAndTaxesIndicatorProps
> = ({
  currencyIsoCode,
  priceInCents,
  numTaxBasisPoints,
  includeFeesInPrices,
  includeTaxesInPrices,
}) => {
  const [feesAndTaxes, setFeesAndTaxes] =
    useState<EstimateFeesAndTaxesResponse>();

  const [debouncedPrice] = useDebounce(priceInCents, 500);
  const [debouncedTaxBasisPoints] = useDebounce(numTaxBasisPoints, 500);

  useEffect(() => {
    const init = async () => {
      if (
        debouncedPrice === undefined ||
        debouncedTaxBasisPoints === undefined
      ) {
        return;
      }
      const feesAndTaxes = await estimateFeesAndTaxes(
        debouncedPrice,
        debouncedTaxBasisPoints,
        currencyIsoCode,
        includeFeesInPrices ?? false,
        includeTaxesInPrices ?? false
      );
      setFeesAndTaxes(feesAndTaxes);
    };
    init();
  }, [
    debouncedPrice,
    debouncedTaxBasisPoints,
    currencyIsoCode,
    includeFeesInPrices,
    includeTaxesInPrices,
  ]);

  if (includeFeesInPrices && includeTaxesInPrices) {
    return null;
  }

  if (!feesAndTaxes) {
    return null;
  }

  return (
    <FeesAndTaxesIndicator
      currencyIsoCode={currencyIsoCode}
      feesInCents={feesAndTaxes.fees_in_cents}
      taxesInCents={feesAndTaxes.taxes_in_cents}
      includeFeesInPrices={includeFeesInPrices}
      includeTaxesInPrices={includeTaxesInPrices}
    />
  );
};
