import { useSearchParams } from "react-router-dom";
import { HStack, Spacer, Text } from "@chakra-ui/react";
import { InviteFriendsModal } from "../../../components/InviteFriendsModal";
import { inviteUserToEvent } from "../../../services/api/events";
import { VianikoEvent } from "../../../types/events";
import { eventShowUrl } from "../../../services/routes/urlBuilder";
import { useCurrentEventUser } from "../../../providers/CurrentEventUserProvider";
import {
  Permission,
  PermissionDropdown,
} from "../../../components/PermissionDropdown";
import { updateEventUser } from "../../../services/api/eventUsers";
import { useAttendees } from "../hooks/useAttendees";
import { Panel } from "../../../components/Panel";

interface EventInviteFriendsModalProps {
  event: VianikoEvent;
  isOpen: boolean;
  onClose: () => void;
}

export const EventInviteFriendsModal: React.FC<
  EventInviteFriendsModalProps
> = ({ event, isOpen, onClose }) => {
  const { currentEventUser, refetch } = useCurrentEventUser();
  const { attendees } = useAttendees(event.id);
  const [searchParams] = useSearchParams();

  const isWelcome = searchParams.get("welcome") === "true";

  const handleInvite = async (userId: string): Promise<any> => {
    return await inviteUserToEvent(event.id, userId);
  };

  const handleChangePermission = async (value: Permission) => {
    if (!currentEventUser) return;

    await updateEventUser(currentEventUser.id, {
      findable: value === "everyone",
    });
    await refetch();
  };

  return (
    <InviteFriendsModal
      isOpen={isOpen}
      inviteLink={`${process.env.REACT_APP_BASE_URL}${eventShowUrl(event.id)}`}
      onClose={onClose}
      onInvite={handleInvite}
      heading="You're going!"
      excludeUserIds={attendees?.map((attendee) => attendee.id)}
      socialSharingOptions={{
        whatsappText: `I'll be attending ${event.name} and would love for you to join. Sign up here: `,
        smsText: `I'll be attending ${event.name} and would love for you to join. Sign up here: `,
      }}
      renderModalBodyTop={
        isWelcome && (
          <Panel padding={"16px"}>
            <HStack marginY={2}>
            <Text>Who sees your RSVP?</Text>
              <Spacer />

              <PermissionDropdown
                value={currentEventUser?.findable ? "everyone" : "me"}
                onChange={handleChangePermission}
              />
            </HStack>
          </Panel>
        )
      }
    />
  );
};
