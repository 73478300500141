import { Organization } from "../types/organization";

export const getRefundDescription = (organization: Organization) => {
  if (organization.is_refundable) {
    if (organization.num_days_refundable_ahead === 0) {
      return "Cancelations are permitted up to the start time";
    } else if (organization.num_days_refundable_ahead) {
      const untilText =
        organization.num_days_refundable_ahead <= 3
          ? `${organization.num_days_refundable_ahead * 24} hours`
          : `${organization.num_days_refundable_ahead} days`;

      return `Cancelations are permitted up to ${untilText} before start time`;
    }
  }
  return "Cancelations are not permitted for this event";
};
