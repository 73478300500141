import { VianikoEvent } from "../../types/events";
import { useMemo, useCallback } from "react";
import { parseISO, getDate, getMonth, getYear } from "date-fns";
import { toZonedTime } from "date-fns-tz";

export type EventsByDateInTimezone = {
  day: number;
  month: number;
  year: number;
  events: VianikoEvent[];
};

export type UseEventsByDateInTimezone = EventsByDateInTimezone[];

export const useEventsByDateInTimezone = (
  events: VianikoEvent[]
): UseEventsByDateInTimezone => {
  // Memoize the timezone conversion function
  const getZonedTime = useCallback(
    (dateString: string, timezone: string) =>
      toZonedTime(parseISO(dateString), timezone),
    []
  );

  // Memoize the date parts extraction functions
  const getDateParts = useCallback(
    (date: Date) => ({
      day: getDate(date),
      month: getMonth(date) + 1, // getMonth returns 0-11, we want 1-12
      year: getYear(date),
    }),
    []
  );

  // Memoize the event sorting function
  const sortEvents = useCallback(
    (a: VianikoEvent, b: VianikoEvent) => {
      const aTime = getZonedTime(a.start_at, a.iana_timezone);
      const bTime = getZonedTime(b.start_at, b.iana_timezone);
      return aTime.getTime() - bTime.getTime();
    },
    [getZonedTime]
  );

  // Memoize the date comparison function
  const compareDates = useCallback((a: ReturnType<typeof getDateParts>, b: ReturnType<typeof getDateParts>) => {
    if (a.year !== b.year) return a.year - b.year;
    if (a.month !== b.month) return a.month - b.month;
    return a.day - b.day;
  }, []);

  return useMemo(() => {
    // First group events by date key
    const eventsByDate: {
      [key: string]: {
        dateParts: ReturnType<typeof getDateParts>;
        events: VianikoEvent[];
      };
    } = {};

    events.forEach((event) => {
      const zonedStartTime = getZonedTime(event.start_at, event.iana_timezone);
      const dateParts = getDateParts(zonedStartTime);
      const dateKey = `${dateParts.year}-${String(dateParts.month).padStart(2, '0')}-${String(dateParts.day).padStart(2, '0')}`;

      if (!eventsByDate[dateKey]) {
        eventsByDate[dateKey] = {
          dateParts,
          events: [],
        };
      }

      eventsByDate[dateKey].events.push(event);
    });

    // Convert to required format and sort by date
    return Object.values(eventsByDate)
      .sort((a, b) => compareDates(a.dateParts, b.dateParts))
      .map(({ dateParts, events: dayEvents }) => ({
        ...dateParts,
        events: dayEvents.sort(sortEvents),
      }));
  }, [events, getZonedTime, getDateParts, sortEvents, compareDates]);
};
