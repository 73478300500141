import { VStack, Box, Text } from "@chakra-ui/react";
import { EventCalendarCard } from "./EventCalendarCard";
import { UseEventsByDateInTimezone } from "../../../hooks/transformers/useEventsByDateInTimezone";
import EventCalendarGroupHeading from "./EventCalendarGroupHeading";
import { TEXT_SECONDARY_COLOR } from "../../../services/theme/colors";

interface EventCalendarListProps {
  groupedEvents: UseEventsByDateInTimezone;
}

export const EventCalendarList: React.FC<EventCalendarListProps> = ({
  groupedEvents,
}) => {
  return (
    <VStack alignItems="stretch">
      {groupedEvents.map((eventGroup, idx) => {
        return (
          <Box key={idx} mb={3}>
            <EventCalendarGroupHeading
              day={eventGroup.day}
              month={eventGroup.month}
              year={eventGroup.year}
            />

            <VStack alignItems="stretch">
              {eventGroup.events.map((event) => (
                <EventCalendarCard key={event.id} event={event} />
              ))}
            </VStack>
          </Box>
        );
      })}
    </VStack>
  );
};

export const EventCalendarEmptyState = () => {
  return (
    <Text color={TEXT_SECONDARY_COLOR} textAlign="center">
      More events coming soon
    </Text>
  );
};
