import { Control, FieldErrors, useController } from "react-hook-form";

import { VianikoEvent } from "../../../../types/events";
import { RecurringEvent } from "../../../../types/recurring_events";
import { CurrencySelectInput } from "../../../../components/forms/CurrencySelectInput";

interface PaymentConfigurationFormSectionProps {
  control: Control<VianikoEvent & RecurringEvent>;
  errors?: FieldErrors<VianikoEvent & RecurringEvent>;
}

export const PaymentConfigurationFormSection: React.FC<
  PaymentConfigurationFormSectionProps
> = ({ control, errors }) => {
  const {
    field: {
      onChange: currencyCodeOnChange,
      value: currencyCodeValue,
      name: currencyCodeName,
    },
  } = useController({
    name: "currency_iso_code",
    defaultValue: "USD",
    control,
  });

  return (
    <>
      <CurrencySelectInput
        name={currencyCodeName}
        onChange={currencyCodeOnChange}
        value={currencyCodeValue}
        isInvalid={!!errors?.currency_iso_code}
      />
    </>
  );
};
