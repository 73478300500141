import { Select, StyleProps } from "@chakra-ui/react";
import React from "react";
import { CURRENCIES } from "../../services/currencies";

interface CurrencySelectInputProps extends StyleProps {
  onChange: (value: string) => void;
  value: string;
  name: string;
  isInvalid?: boolean;
}

export const CurrencySelectInput: React.FC<CurrencySelectInputProps> = ({
  onChange,
  value,
  name,
  isInvalid,
  ...styleProps
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(e.target.value);
  };

  return (
    <Select
      variant="subtle"
      width="fit-content"
      name={name}
      onChange={handleChange}
      value={value}
      isInvalid={isInvalid}
      {...styleProps}
    >
      {CURRENCIES.map((currency) => (
        <option key={currency.code} value={currency.code}>
          {currency.code}
        </option>
      ))}
    </Select>
  );
};
