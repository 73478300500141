import {
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Control, useController, FieldValues, Path } from "react-hook-form";
import { TEXT_SECONDARY_COLOR } from "../../services/theme/colors";
import { getStripeFeeDescription } from "../../services/payments";
import { PercentInput } from "../../components/forms/PercentInput";

interface TaxesAndFeesFormSectionProps<T extends FieldValues> {
  control: Control<T>;
  currencyIsoCode?: string;
  isOrganizationForm?: boolean;
}

export const TaxesAndFeesFormSection = <T extends FieldValues>({
  control,
  currencyIsoCode,
  isOrganizationForm = false,
}: TaxesAndFeesFormSectionProps<T>) => {
  const {
    field: { value: includeFeesInPrices, onChange: onChangeIncludeFees },
  } = useController({
    name: "include_fees_in_prices" as Path<T>,
    control,
  });

  const {
    field: { value: includeTaxesInPrices, onChange: onChangeIncludeTaxes },
  } = useController({
    name: "include_taxes_in_prices" as Path<T>,
    control,
  });

  const {
    field: { value: numTaxBasisPoints, onChange: onChangeTaxBasisPoints },
  } = useController({
    name: "num_tax_basis_points" as Path<T>,
    control,
  });

  const collectTax = numTaxBasisPoints > 0;
  const handleCollectTaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeTaxBasisPoints(e.target.checked ? 100 : 0);
    if (e.target.checked) {
      onChangeIncludeTaxes(true);
    }
  };

  return (
    <VStack alignItems="stretch" gap={2}>
      <Heading size="lg" as="h2" marginTop={4}>
        Fees & Taxes
      </Heading>

      <Heading size="sm" marginBottom={2}>
        How should fees be displayed?
      </Heading>
      <FormControl>
        <HStack>
          <Checkbox
            isChecked={!includeFeesInPrices}
            onChange={(e) => onChangeIncludeFees(!e.target.checked)}
          />
          <FormLabel margin={0}>Add fees to price</FormLabel>
        </HStack>
      </FormControl>
      <Text
        fontSize="sm"
        color={TEXT_SECONDARY_COLOR}
        marginTop={1}
        marginX={4}
      >
        Feather will add the typical stripe fee of{" "}
        {getStripeFeeDescription(currencyIsoCode)} to your tickets, passes and
        memberships. Fees may be higher for international credit cards.
      </Text>

      <FormControl marginTop={4}>
        <HStack>
          <Checkbox isChecked={collectTax} onChange={handleCollectTaxChange} />
          <FormLabel margin={0}>Collect tax</FormLabel>
        </HStack>
      </FormControl>

      {collectTax && (
        <>
          <FormControl marginTop={2}>
            <HStack marginX={4}>
              <Text fontSize="sm">Tax rate</Text>
              <PercentInput<T>
                control={control}
                size="sm"
                name={"num_tax_basis_points" as Path<T>}
              />
            </HStack>
          </FormControl>

          <Heading size="sm" marginTop={4} marginBottom={2}>
            How should taxes be displayed?
          </Heading>
          <FormControl>
            <HStack>
              <Checkbox
                isChecked={!includeTaxesInPrices}
                onChange={(e) => onChangeIncludeTaxes(!e.target.checked)}
              />
              <FormLabel margin={0}>Add taxes to price</FormLabel>
            </HStack>
          </FormControl>
          {isOrganizationForm && (
            <Text
              fontSize="sm"
              color={TEXT_SECONDARY_COLOR}
              marginTop={1}
              marginX={4}
            >
              Taxes and fees can be changed for individual tickets and passes in
              the ticket creation window
            </Text>
          )}
        </>
      )}
    </VStack>
  );
};
