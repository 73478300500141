import { useState } from "react";
import { Button, Text } from "@chakra-ui/react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { createStripeSubscription } from "../../services/api/passes";
import { Pass } from "../../types/passes";
import {
  eventShowUrl,
  organizationShowUrl,
} from "../../services/routes/urlBuilder";
import { useSearchParams } from "react-router-dom";
import { useOrganization } from "../../providers/CurrentOrganizationProvider";

interface SubscriptionElementsContentProps {
  pass: Pass;
}

export const SubscriptionElementsContent: React.FC<
  SubscriptionElementsContentProps
> = ({ pass }) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [stripeError, setStripeError] = useState<string>();
  const [searchParams] = useSearchParams();
  const eventIdToEnroll = searchParams.get("eventIdToEnroll") || undefined;

  const stripe = useStripe();

  const elements = useElements();

  const { organization } = useOrganization(pass.organization_id);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements) return;

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setStripeError(submitError.message);
      return;
    }

    setSubmitting(true);

    const { paymentToken, type } = await createStripeSubscription(pass.id, {
      eventIdToEnroll,
    });

    const confirmIntent =
      type === "setup" ? stripe.confirmSetup : stripe.confirmPayment;

    const result = await confirmIntent({
      elements,
      clientSecret: paymentToken,
      confirmParams: {
        return_url: `${process.env.REACT_APP_BASE_URL}${
          eventIdToEnroll
            ? eventShowUrl(eventIdToEnroll, {
                welcome: true,
                invite: true,
              })
            : organizationShowUrl(organization?.slug || "")
        }`,
      },
    });
    setSubmitting(false);

    if (result.error) {
      setStripeError(result.error.message);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      {stripeError && <Text color="red.700">{stripeError}</Text>}
      <Button
        type="submit"
        variant="primary"
        width="100%"
        size="lg"
        marginTop={4}
        marginBottom={12}
        disabled={!stripe || submitting}
      >
        Submit
      </Button>
    </form>
  );
};
