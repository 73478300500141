import { useState } from "react";
import { useCalendarFromStartingDate } from "../../../hooks/transformers/useCalendarFromDate";
import { format, addWeeks, isSameDay } from "date-fns";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  HStack,
  VStack,
  IconButton,
  Text,
  Box,
  useBreakpointValue,
} from "@chakra-ui/react";
import { EventsByDateInTimezone } from "../../../hooks/transformers/useEventsByDateInTimezone";
import { TEXT_PRIMARY_COLOR } from "../../../services/theme/colors";

interface WeekCalendarProps {
  onNext: (startAt: string) => void;
  onPrevious: (startAt: string) => void;
  onDaySelected: (selectedDate: Date) => void;
  eventsByDay: EventsByDateInTimezone[];
  selectedDate?: Date;
  startDate?: Date;
}

export const WeekCalendar: React.FC<WeekCalendarProps> = ({
  onNext,
  onPrevious,
  onDaySelected,
  eventsByDay,
  selectedDate = new Date(),
  startDate = new Date(),
}) => {
  const [weekOffset, setWeekOffset] = useState(0);
  const calendarDays = useCalendarFromStartingDate(startDate, weekOffset);
  const isSmallScreen = useBreakpointValue({ base: true, sm: false });

  const handlePrevious = () => {
    const nextOffset = weekOffset - 1;
    const nextStartDate = addWeeks(startDate, nextOffset);

    setWeekOffset(nextOffset);
    onPrevious(nextStartDate.toISOString());
  };

  const handleNext = () => {
    const nextOffset = weekOffset + 1;
    const nextStartDate = addWeeks(startDate, nextOffset);

    setWeekOffset(nextOffset);
    onNext(nextStartDate.toISOString());
  };

  return (
    <HStack spacing={isSmallScreen ? 0 : 2} p={isSmallScreen ? 0 : 2}>
      <IconButton
        aria-label="Previous week"
        icon={<ChevronLeftIcon />}
        onClick={handlePrevious}
        variant="ghost"
        size="sm"
      />

      <HStack flex={1} spacing={isSmallScreen ? 0 : 1} justify="space-between">
        {calendarDays.map((day) => {
          const dayHasEvents = eventsByDay.some(
            (eventDay) =>
              eventDay.day === day.date.getDate() &&
              eventDay.month === day.date.getMonth() + 1 &&
              eventDay.year === day.date.getFullYear()
          );

          return (
            <Box
              key={day.date.toISOString()}
              width={isSmallScreen ? "32px" : "38px"}
              as="button"
              backgroundColor={
                isSameDay(day.date, selectedDate) ? "#0000001A" : "transparent"
              }
              onClick={() => onDaySelected(day.date)}
              borderRadius={"5px"}
            >
              <VStack spacing={0} flex={1} pb={dayHasEvents ? 0 : 4}>
                <Text
                  fontSize="xs"
                  textTransform="uppercase"
                  color={day.isToday ? "blue.500" : "gray.700"}
                  fontWeight={day.isToday ? "semibold" : "normal"}
                >
                  {format(day.date, "EEE")}
                </Text>
                <Text
                  fontSize="xl"
                  color={day.isToday ? "blue.500" : "gray.700"}
                  fontWeight={day.isToday ? "semibold" : "normal"}
                >
                  {format(day.date, "d")}
                </Text>
                <Text
                  fontSize="xs"
                  textTransform="uppercase"
                  color={day.isToday ? "blue.500" : "gray.700"}
                  fontWeight={day.isToday ? "semibold" : "normal"}
                >
                  {format(day.date, "MMM")}
                </Text>
                {dayHasEvents && (
                  <Box
                    width="8px"
                    height="8px"
                    borderRadius="50%"
                    backgroundColor={TEXT_PRIMARY_COLOR}
                    mb={2}
                  />
                )}
              </VStack>
            </Box>
          );
        })}
      </HStack>

      <IconButton
        aria-label="Next week"
        icon={<ChevronRightIcon />}
        onClick={handleNext}
        variant="ghost"
        size="sm"
      />
    </HStack>
  );
};
