import { Heading, Text } from "@chakra-ui/react";
import {
  DEFAULT_CURRENCY_ISO_CODE,
  priceInDollars,
} from "../../services/payments";
import { CURRENCY_CODE_TO_CURRENCY } from "../../services/currencies";
import { Elements } from "@stripe/react-stripe-js";
import { useOrganization } from "../../providers/CurrentOrganizationProvider";
import { useStripeAccount } from "../../hooks/useStripeAccount";
import { Pass } from "../../types/passes";
import { PaymentForm } from "../events/components/PaymentForm";
import { useEffect, useState } from "react";
import { createPassPaymentIntent } from "../../services/api/payments";
import {
  eventShowUrl,
  organizationShowUrl,
} from "../../services/routes/urlBuilder";
import { useSearchParams } from "react-router-dom";

interface PassOneTimePaymentProps {
  pass: Pass;
}

export const PassOneTimePayment: React.FC<PassOneTimePaymentProps> = ({
  pass,
}) => {
  const [paymentToken, setPaymentToken] = useState<string>();
  const [totalPriceInCents, setTotalPriceInCents] = useState<number>();
  const { organization } = useOrganization(pass.organization_id);
  const { stripe } = useStripeAccount(organization?.id);
  const [searchParams] = useSearchParams();

  const eventIdToEnroll = searchParams.get("eventIdToEnroll") || undefined;

  useEffect(() => {
    const init = async () => {
      const { paymentToken, amount } = await createPassPaymentIntent(pass.id, {
        eventIdToEnroll,
      });
      setPaymentToken(paymentToken);
      setTotalPriceInCents(amount);
    };

    init();
  }, [pass, eventIdToEnroll]);

  if (!pass || !stripe || !paymentToken || !organization || !totalPriceInCents)
    return null;

  return (
    <>
      <Heading size="md" marginY={2}>
        Payment for {pass.name}
      </Heading>
      <Text marginY={4}>
        {priceInDollars(
          organization.currency_iso_code ?? DEFAULT_CURRENCY_ISO_CODE,
          totalPriceInCents
        )}{" "}
        {
          CURRENCY_CODE_TO_CURRENCY[
            organization.currency_iso_code ?? DEFAULT_CURRENCY_ISO_CODE
          ].code
        }
      </Text>

      <Elements
        stripe={stripe}
        options={{
          clientSecret: paymentToken,
        }}
      >
        <PaymentForm
          returnUrl={`${process.env.REACT_APP_BASE_URL}${
            eventIdToEnroll
              ? eventShowUrl(eventIdToEnroll, {
                  welcome: true,
                  invite: true,
                })
              : organizationShowUrl(organization?.slug || "")
          }`}
        />
      </Elements>
    </>
  );
};
