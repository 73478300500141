import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";
import { createContext, useEffect, useState } from "react";

interface AmplitudeContextValue {
  amplitude: any;
}

export const AmplitudeContext = createContext<AmplitudeContextValue>({
  amplitude: null,
});

export const AmplitudeProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [amplitudeInstance, setAmplitudeInstance] = useState<any>(null);

  useEffect(() => {
    const init = () => {
      const sessionReplayTracking = sessionReplayPlugin();
      amplitude.add(sessionReplayTracking);

      amplitude.init("22cd06dcd72fdb9024871eb2cfd833cf", {
        autocapture: true,
        fetchRemoteConfig: true,
      });

      setAmplitudeInstance(amplitude);
    };
    init();
  }, []);

  return (
    <AmplitudeContext.Provider value={{ amplitude: amplitudeInstance }}>
      {children}
    </AmplitudeContext.Provider>
  );
};
