import { useSearchParams } from "react-router-dom";
import { SignAllWaiversModals } from "./SignAllWaiversModals";
import { useCurrentOrganization } from "../../providers/CurrentOrganizationProvider";

export const SignAllWaivers: React.FC = () => {
  const { organization } = useCurrentOrganization();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get("redirectTo");

  if (!organization) return null;

  return (
    <SignAllWaiversModals
      organization={organization}
      redirectTo={redirectTo || undefined}
    />
  );
};
