import { Box, Container, Flex, useBreakpointValue } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { AppFooter } from "../components/AppFooter";
import { AppHeader } from "../components/AppHeader";
import { useCurrentUser } from "../providers/CurrentUserProvider";
import { BACKGROUND_GRADIENT } from "../services/theme/colors";

const headerHeight = "50px";

export type RootContainerSizes = "sm" | "md" | "lg" | "xl" | "2xl";

interface RootProps {
  containerSize?: RootContainerSizes;
}

export const Root: React.FC<RootProps> = ({ containerSize = "sm" }) => {
  const { currentUser } = useCurrentUser();
  const isMobileSize = useBreakpointValue({ base: true, sm: false });

  const displayFooter = currentUser && isMobileSize;

  return (
    <Flex height="100svh" direction="column">
      <Box
        background={BACKGROUND_GRADIENT}
        flexGrow={1}
        width="100%"
        overflowY="scroll"
        overflowX="hidden"
      >
        <Box height="auto">
          <Box height={headerHeight} paddingX={5}>
            <AppHeader />
          </Box>
          <Box padding={1}>
            <Container maxW={`container.${containerSize}`}>
              <Outlet />
            </Container>
          </Box>
        </Box>
      </Box>
      {displayFooter && <AppFooter user={currentUser} />}
    </Flex>
  );
};
