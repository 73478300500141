import {
  Button,
  Flex,
  HStack,
  Image,
  Link,
  Spacer,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Link as RouterLink, useLocation, useParams } from "react-router-dom";
import VianikoLogo from "../assets/logos/logo.svg";

import { useCurrentUser } from "../providers/CurrentUserProvider";
import {
  authUrl,
  eventNewUrl,
  homeUrl,
  userShowUrl,
} from "../services/routes/urlBuilder";
import { BiCalendar, BiHome, BiPlus } from "react-icons/bi";
import { ButtonLink } from "./ButtonLink";
import { useOrganizationUser } from "../providers/CurrentOrganizationUserProvider";
import {
  hasPermission,
  PERMISSION_MANAGE_EVENTS,
} from "../services/permissions";
import { UserAccountDropdown } from "./UserAccountDropdown";
import { useOrganization } from "../providers/CurrentOrganizationProvider";

export const AppHeader: React.FC = () => {
  const { currentUser } = useCurrentUser();
  const location = useLocation();

  const displayLink = useBreakpointValue({ base: false, sm: true });
  const navIconSize = useBreakpointValue({ base: "30px", sm: "18px" });
  const navIconContainerSize = useBreakpointValue({ base: "40px", sm: "18px" });
  const createEventText = useBreakpointValue({
    base: "Event",
    md: "Create event",
  });

  const { organizationId } = useParams();
  const { organization } = useOrganization(organizationId);
  const { organizationUser } = useOrganizationUser(organization?.id);
  const showCreateEventButton =
    organization &&
    (hasPermission(organizationUser?.permissions, PERMISSION_MANAGE_EVENTS) ||
      organizationUser?.is_owner);

  return (
    <HStack height="100%">
      <Button
        variant="unstyled"
        padding={0}
        marginTop={2}
        onClick={() => (window.location.href = homeUrl())}
      >
        <Image src={VianikoLogo} height="40px" />
      </Button>

      <Spacer />

      <HStack spacing={displayLink ? 3 : 0} height="100%">
        {displayLink && (
          <Link as={RouterLink} to={homeUrl()}>
            <Flex direction={"row"} gap={1} alignItems="center" marginTop={2}>
              <Flex
                boxSize={navIconContainerSize}
                alignItems="center"
                justifyContent="center"
              >
                <BiHome fontSize={navIconSize} />
              </Flex>
              <Text>Home</Text>
            </Flex>
          </Link>
        )}

        {currentUser && displayLink && (
          <Link
            as={RouterLink}
            to={userShowUrl(currentUser.id, {
              tab: "events",
              eventsDirection: "future",
            })}
          >
            <Flex direction={"row"} gap={1} alignItems="center" marginTop={2}>
              <Flex
                boxSize={navIconContainerSize}
                alignItems="center"
                justifyContent="center"
              >
                <BiCalendar fontSize={navIconSize} />
              </Flex>
              <Text>Calendar</Text>
            </Flex>
          </Link>
        )}

        {showCreateEventButton && (
          <Button
            as={RouterLink}
            to={eventNewUrl({ slug: organization.slug })}
            variant="primary"
            marginTop={2}
          >
            <Flex direction={"row"} gap={1} alignItems="center">
              <BiPlus fontSize={"18px"} />
              <Text>{createEventText}</Text>
            </Flex>
          </Button>
        )}
      </HStack>

      {currentUser ? (
        <UserAccountDropdown user={currentUser} />
      ) : (
        <>
          {!location.pathname.startsWith("/auth") && (
            <ButtonLink
              to={authUrl(homeUrl())}
              buttonProps={{ variant: "primary", marginTop: 2 }}
            >
              Sign in
            </ButtonLink>
          )}
        </>
      )}
    </HStack>
  );
};
