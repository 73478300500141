import { Elements } from "@stripe/react-stripe-js";
import { useParams, useSearchParams } from "react-router-dom";
import { PaymentForm } from "../events/components/PaymentForm";
import { Heading, Text } from "@chakra-ui/react";
import { priceInDollars } from "../../services/payments";
import { useStripeAccount } from "../../hooks/useStripeAccount";
import { eventShowUrl } from "../../services/routes/urlBuilder";
import { useEvent } from "../../hooks/useEvent";

export const EventPaymentPage: React.FC = () => {
  const { eventId } = useParams();
  const { event } = useEvent(eventId);
  const [searchParams] = useSearchParams();
  const { stripe } = useStripeAccount(event?.organization_id);

  const amountInCents = parseInt(searchParams.get("amountInCents") || "0");
  const paymentToken = searchParams.get("paymentToken");

  if (!paymentToken || !amountInCents || !event) {
    return null;
  }

  return (
    <>
      <Heading size="md" marginY={2}>
        Payment for {event.name}
      </Heading>
      <Text marginY={4}>
        {priceInDollars(event.currency_iso_code, amountInCents)}{" "}
        {event.currency_iso_code}
      </Text>
      <Elements stripe={stripe} options={{ clientSecret: paymentToken }}>
        <PaymentForm
          returnUrl={`${process.env.REACT_APP_BASE_URL}${eventShowUrl(
            event.id,
            { invite: true, welcome: true }
          )}`}
        />
      </Elements>
    </>
  );
};
