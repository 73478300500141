import { useCallback, useEffect, useState } from "react";
import { VianikoUser } from "../types/users";
import { fetchAllFriendships } from "../services/api/friendships";
import { useCurrentUser } from "../providers/CurrentUserProvider";

export type UseFriendshipFriend = VianikoUser & { friendship_id: string };

export interface UseFriendships {
  friends: UseFriendshipFriend[] | undefined;
  friendsByUserId: Record<string, UseFriendshipFriend>;
  refetch: () => void;
}

export const useFriendships = (userId: string | undefined): UseFriendships => {
  const [friends, setFriends] = useState<UseFriendshipFriend[]>();
  const [friendsByUserId, setFriendsByUserId] = useState<
    Record<string, UseFriendshipFriend>
  >({});
  const { currentUser } = useCurrentUser();

  const fetchFriends = useCallback(async () => {
    if (!userId || !currentUser) return;

    const fetchedFriendships = await fetchAllFriendships(userId);
    setFriends(fetchedFriendships);

    const friendsByUserId = fetchedFriendships.reduce(
      (acc, friendship) => ({
        ...acc,
        [friendship.id]: friendship,
      }),
      {}
    );

    setFriendsByUserId(friendsByUserId);
  }, [userId, currentUser]);

  useEffect(() => {
    fetchFriends();
  }, [fetchFriends]);

  return { friends, friendsByUserId, refetch: fetchFriends };
};
