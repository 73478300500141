import React, { useEffect, useState } from "react";
import {
  Box,
  Circle,
  HStack,
  Icon,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spacer,
  StyleProps,
  Text,
} from "@chakra-ui/react";
import { BiDollarCircle } from "react-icons/bi";
import { TicketType } from "../../../../types/ticket_types";
import { Control, useController } from "react-hook-form";
import { priceInDollars } from "../../../../services/payments";
import { TEXT_SECONDARY_COLOR } from "../../../../services/theme/colors";

interface SlidingPriceProps extends StyleProps {
  name: string;
  currencyIsoCode: string;
  ticketType: TicketType;
  control: Control<any>;
  defaultValue?: number;
}

export const SlidingPrice: React.FC<SlidingPriceProps> = ({
  name,
  currencyIsoCode,
  ticketType,
  control,
  defaultValue,
  ...styleProps
}) => {
  const [defaultValueInitialized, setDefaultValueInitialized] = useState(false);

  const {
    field: { onChange: handleChange, value },
  } = useController({
    name,
    control,
  });

  useEffect(() => {
    if (defaultValue && !defaultValueInitialized) {
      handleChange(defaultValue);
      setDefaultValueInitialized(true);
    }
  }, [defaultValue, defaultValueInitialized, handleChange]);

  const minPriceInCents = ticketType.min_price_in_cents || 0;
  const maxPriceInCents = ticketType.max_price_in_cents || 10000;
  const defaultPriceInCents = ticketType.price_in_cents || 0;

  const defaultPricePercent =
    (100 * (defaultPriceInCents - minPriceInCents)) /
    (maxPriceInCents - minPriceInCents);

  return (
    <Box {...styleProps}>
      <Slider
        min={minPriceInCents}
        max={maxPriceInCents}
        step={100}
        onChange={handleChange}
        value={value}
        colorScheme="blackAlpha"
        aria-label="sliding-price"
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb boxSize={6} bgColor="gray.200">
          <Circle size={6} backgroundColor="white" border="2px solid black">
            <Icon as={BiDollarCircle} fontSize="18px" />
          </Circle>
        </SliderThumb>
      </Slider>
      <HStack position="relative">
        <Text size="sm" color={TEXT_SECONDARY_COLOR}>
          {priceInDollars(currencyIsoCode, minPriceInCents, 0)}
        </Text>
        <Spacer />
        <Text size="sm" color={TEXT_SECONDARY_COLOR}>
          {priceInDollars(currencyIsoCode, maxPriceInCents, 0)}
        </Text>

        {defaultPricePercent > 20 && defaultPricePercent < 80 && (
          <Text
            position="absolute"
            left={`${defaultPricePercent}%`}
            transform={"translate(-50%)"}
            size="sm"
            color={TEXT_SECONDARY_COLOR}
          >
            {priceInDollars(currencyIsoCode, defaultPriceInCents, 0)}
          </Text>
        )}
      </HStack>
    </Box>
  );
};
