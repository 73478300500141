import { Control, useController } from "react-hook-form";
import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { inputDefaultThemeProps } from "../../services/theme/overrides/input";
import { priceInDollars } from "../../services/payments";
import { getCurrencyByCode } from "../../services/currencies";

interface DollarsInputProps extends FormControlProps {
  name: string;
  label: string;
  control: Control<any>;
  defaultValue?: number;
  min: number;
  currencyIsoCode?: string;
}

const parseDollar = (val: string | number, currencyIsoCode: string) => {
  const currencySymbol = getCurrencyByCode(currencyIsoCode).symbol;

  let replacedVal = val;
  if (val && typeof val === "string" && val.startsWith(currencySymbol)) {
    replacedVal = val.substring(currencySymbol.length);
    replacedVal = replacedVal.replace(/[.]/g, "");
  }

  const result = Number(replacedVal);
  return isNaN(result) ? undefined : result;
};

export const DollarsInput: React.FC<DollarsInputProps> = ({
  name,
  label,
  control,
  defaultValue,
  min,
  currencyIsoCode = "USD",
  ...formControlProps
}) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: defaultValue || 0,
    rules: {
      min: {
        value: min,
        message: `Must be at least ${priceInDollars(currencyIsoCode, min)}`,
      },
      required: "This field is required",
    },
  });

  return (
    <FormControl isInvalid={!!error} width="100%" {...formControlProps}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}

      <Input
        type="text"
        variant="primary"
        onChange={(e) => {
          const nextValue = parseDollar(e.target.value, currencyIsoCode);
          if (typeof nextValue === "number" && !isNaN(nextValue)) {
            onChange(nextValue);
          }
        }}
        value={priceInDollars(currencyIsoCode, value)}
        {...inputDefaultThemeProps}
      />
      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};
