import { Box, Text, Divider } from "@chakra-ui/react";
import { isToday, format } from "date-fns";

interface EventCalendarGroupHeadingProps {
  day: number;
  month: number;
  year: number;
}

export default function EventCalendarGroupHeading({
  day,
  month,
  year,
}: EventCalendarGroupHeadingProps) {
  const isEventGroupToday = isToday(new Date(year, month - 1, day));

  const longformEventDate = format(
    new Date(year, month - 1, day),
    "EEEE MMMM d"
  );

  return (
    <Box>
      <Text fontWeight="bold" pb={"5px"}>
        {isEventGroupToday ? "Today" : longformEventDate}
      </Text>
      <Divider borderWidth="1px" color="rgba(0, 0, 0, 0.1)" mb={"0.75em"} />
    </Box>
  );
}
