import {
  Box,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { TicketType } from "../../../../types/ticket_types";
import { TiTicket } from "react-icons/ti";
import { BiDotsHorizontalRounded, BiPencil, BiTrashAlt } from "react-icons/bi";
import { deleteTicketType } from "../../../../services/api/ticketTypes";
import { DeleteConfirmationModal } from "../../../../components/DeleteConfirmationModal";
import { useState } from "react";
import {
  priceForTicketType,
  priceInDollars,
} from "../../../../services/payments";
import { FeesAndTaxesIndicator } from "../../../../components/FeesAndTaxesIndicator";

interface TicketTypesSectionProps {
  ticketTypes?: TicketType[];
  currencyIsoCode: string;
  canManageTickets: boolean;
  onEdit?: (ticketType: TicketType) => void;
  onDelete?: (ticketType: TicketType) => void;
}

export const TicketTypesSection: React.FC<TicketTypesSectionProps> = ({
  ticketTypes,
  currencyIsoCode,
  canManageTickets,
  onEdit,
  onDelete,
}) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [ticketTypeToDelete, setTicketTypeToDelete] =
    useState<TicketType | null>(null);

  const handleDeleteTicketType = async () => {
    if (!ticketTypeToDelete) return;

    await deleteTicketType(ticketTypeToDelete.id);
    if (onDelete) {
      onDelete(ticketTypeToDelete);
    }

    setDeleteModalOpen(false);
  };

  const handleClickDelete = (ticketType: TicketType) => {
    setTicketTypeToDelete(ticketType);
    setDeleteModalOpen(true);
  };

  return (
    <>
      <DeleteConfirmationModal
        isOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onDelete={handleDeleteTicketType}
      />

      <VStack width="100%">
        {ticketTypes?.map((ticketType: TicketType, index) => (
          <HStack key={index} width="100%">
            <Icon as={TiTicket} />
            <Box>
              <Text size="md">{ticketType.name}</Text>
              <HStack>
                {ticketType.payment_type === "slider" ? (
                  <>
                    <Text size="sm" variant="secondary">
                      {priceInDollars(
                        currencyIsoCode,
                        ticketType.min_price_in_cents
                      )}{" "}
                      -
                      {priceInDollars(
                        currencyIsoCode,
                        ticketType.max_price_in_cents
                      )}
                    </Text>
                    {!ticketType.include_fees_in_prices ||
                    !ticketType.include_taxes_in_prices ? (
                      <Text size="sm" variant="secondary">
                        +{" "}
                        {!ticketType.include_fees_in_prices &&
                        !ticketType.include_taxes_in_prices
                          ? "fees and taxes"
                          : !ticketType.include_fees_in_prices
                          ? "fees"
                          : "taxes"}
                      </Text>
                    ) : null}
                  </>
                ) : (
                  <>
                    <Text size="sm" variant="secondary">
                      {priceForTicketType(
                        ticketType.payment_type,
                        ticketType.price_in_cents,
                        currencyIsoCode
                      )}
                    </Text>
                    <FeesAndTaxesIndicator
                      currencyIsoCode={currencyIsoCode}
                      feesInCents={ticketType.fees_in_cents}
                      taxesInCents={ticketType.taxes_in_cents}
                      includeFeesInPrices={ticketType.include_fees_in_prices}
                      includeTaxesInPrices={ticketType.include_taxes_in_prices}
                    />
                  </>
                )}
              </HStack>
            </Box>
            <Spacer />
            {canManageTickets && (
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<Icon as={BiDotsHorizontalRounded} />}
                  variant="ghost"
                />
                <MenuList>
                  <MenuItem onClick={() => onEdit && onEdit(ticketType)}>
                    <Icon as={BiPencil} marginRight={2} />
                    <Text size="md">Edit ticket</Text>
                  </MenuItem>
                  <MenuItem onClick={() => handleClickDelete(ticketType)}>
                    <Icon as={BiTrashAlt} marginRight={2} />
                    <Text size="md">Delete ticket</Text>
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </HStack>
        ))}
      </VStack>
    </>
  );
};
