export const PERMISSION_MANAGE_EVENTS = 1 << 0;
export const PERMISSION_MANAGE_EVENTS_TEXT =
  "Ability to create events and edit event details";

export const PERMISSION_MANAGE_ORGANIZATION = 1 << 1;
export const PERMISSION_MANAGE_ORGANIZATION_TEXT =
  "Ability to manage organization";

export const PERMISSION_CHECKIN = 1 << 2;
export const PERMISSION_CHECKIN_TEXT = "Ability to check in attendees";

export const PERMISSION_WAIVERS = 1 << 3;
export const PERMISSION_WAIVERS_TEXT = "Ability to manage waivers";

export const PERMISSION_MANAGE_EVENT_TICKETS = 1 << 4;
export const PERMISSION_MANAGE_EVENT_TICKETS_TEXT =
  "Ability to manage tickets to events";

export const PERMISSION_MESSAGE_ATTENDEES = 1 << 5;
export const PERMISSION_MESSAGE_ATTENDEES_TEXT =
  "Ability to message event attendees";

export const PERMISSION_MANAGE_PASSES = 1 << 6;
export const PERMISSION_MANAGE_PASSES_TEXT =
  "Ability to manage passes to events";

export const hasPermission = (
  permissions: number | bigint | undefined,
  permission: number | bigint
) => {
  if (!permissions) return false;

  return (BigInt(permissions) & BigInt(permission)) === BigInt(permission); //return bigInt(permissions).and(bigInt(permission)).eq(bigInt(permission));
};

export type PermissionCategory =
  | "Administration"
  | "Event Management"
  | "Attendee Management";

export interface PermissionCategoryGroup {
  category: PermissionCategory;
  permissions: Permission[];
}

export interface Permission {
  value: number;
  label: string;
  category: PermissionCategory;
}

export const groupPermissionsByCategory = (
  permissions: Permission[]
): PermissionCategoryGroup[] => {
  // group permissions by category
  const groupedPermissions: PermissionCategoryGroup[] = [];
  permissions.forEach((permission) => {
    const categoryGroup = groupedPermissions.find(
      (group) => group.category === permission.category
    );

    if (categoryGroup) {
      categoryGroup.permissions.push(permission);
    } else {
      groupedPermissions.push({
        category: permission.category,
        permissions: [permission],
      });
    }
  });
  // sort all permissions inside a category
  groupedPermissions.forEach((group) => {
    group.permissions.sort((a, b) => a.label.localeCompare(b.label));
  });
  return groupedPermissions;
};

export const getGroupedPermissions = (
  permissions: number | bigint | undefined
): PermissionCategoryGroup[] => {
  return groupPermissionsByCategory(getPermissionsList(permissions));
};

export const getPermissionsList = (
  permissions: number | bigint | undefined
): Permission[] => {
  return ALL_PERMISSIONS.filter((permission) => {
    return hasPermission(permissions, permission.value);
  });
};

export const ALL_PERMISSIONS: Permission[] = [
  {
    value: PERMISSION_MANAGE_EVENTS,
    label: PERMISSION_MANAGE_EVENTS_TEXT,
    category: "Event Management",
  },
  {
    value: PERMISSION_MANAGE_ORGANIZATION,
    label: PERMISSION_MANAGE_ORGANIZATION_TEXT,
    category: "Administration",
  },
  {
    value: PERMISSION_CHECKIN,
    label: PERMISSION_CHECKIN_TEXT,
    category: "Attendee Management",
  },
  {
    value: PERMISSION_WAIVERS,
    label: PERMISSION_WAIVERS_TEXT,
    category: "Attendee Management",
  },
  {
    value: PERMISSION_MANAGE_EVENT_TICKETS,
    label: PERMISSION_MANAGE_EVENT_TICKETS_TEXT,
    category: "Event Management",
  },
  {
    value: PERMISSION_MESSAGE_ATTENDEES,
    label: PERMISSION_MESSAGE_ATTENDEES_TEXT,
    category: "Attendee Management",
  },
  {
    value: PERMISSION_MANAGE_PASSES,
    label: PERMISSION_MANAGE_PASSES_TEXT,
    category: "Event Management",
  },
];
