import { Pass } from "../../types/passes";
import { Ticket } from "../../types/ticket";
import { UserPass } from "../../types/user_passes";
import axiosInstance from "./axiosInstance";

export const createPass = async (
  organizationId: string,
  pass: Pass
): Promise<Pass> => {
  const result = await axiosInstance.post(
    `/passes/organizations/${organizationId}`,
    pass
  );
  return result.data;
};

export const updatePass = async (pass: Pass): Promise<Pass> => {
  const result = await axiosInstance.put(`/passes/${pass.id}`, pass);
  return result.data;
};

export const deletePass = async (passId: string): Promise<void> => {
  await axiosInstance.delete(`/passes/${passId}`);
};

export const fetchPass = async (passId: string): Promise<Pass> => {
  const result = await axiosInstance.get(`/passes/${passId}`);
  return result.data;
};

export const fetchPassPublic = async (passId: string): Promise<Pass> => {
  const result = await axiosInstance.get(`/public/passes/${passId}`);
  return result.data;
};

export const fetchPasses = async (organizationId: string): Promise<Pass[]> => {
  const result = await axiosInstance.get(
    `/public/passes/organizations/${organizationId}`
  );
  return result.data;
};

export const fetchCurrentUserPasses = async (
  organizationId: string
): Promise<{ passes: Pass[]; user_passes: UserPass[] }> => {
  const result = await axiosInstance.get(
    `/passes/organizations/${organizationId}/current`
  );
  return result.data;
};

export const createStripeSubscription = async (
  passId: string,
  body?: { eventIdToEnroll?: string }
) => {
  const result = await axiosInstance.post(
    `/payments/passes/${passId}/create-subscription`,
    body
  );
  return result.data;
};

export const createTicketForPass = async (passId: string, eventId: string) => {
  const result = await axiosInstance.post(
    `/passes/${passId}/events/${eventId}/tickets`,
    {
      eventId,
    }
  );
  return result.data;
};

export interface FetchUserPassResponse extends UserPass {
  pass: Pass;
  ticket: Ticket;
  user_pass: UserPass;
}

export const fetchUserPass = async (
  passId: string,
  eventId: string
): Promise<FetchUserPassResponse> => {
  const result = await axiosInstance.get(
    `/passes/${passId}/events/${eventId}/user_pass`
  );
  return result.data;
};
