import { SkeletonText, Stack } from "@chakra-ui/react";
import { useEffect, useRef } from "react";

interface SkeletonLoaderProps {
  onLoadMore: () => void;
}

export const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({
  onLoadMore,
}) => {
  const observerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const currentRef = observerRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          onLoadMore();
        }
      },
      { threshold: 0.1 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [onLoadMore]);

  return (
    <Stack gap="4" ref={observerRef}>
      <SkeletonText noOfLines={1} skeletonHeight={4} width="80%" />
      <SkeletonText noOfLines={1} skeletonHeight={4} width="40%" />
      <SkeletonText noOfLines={1} skeletonHeight={4} width="100%" />
    </Stack>
  );
};
