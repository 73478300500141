import { Button, VStack } from "@chakra-ui/react";
import { Panel } from "../../../../components/Panel";
import { useForm } from "react-hook-form";
import { Organization } from "../../../../types/organization";
import { useCurrentOrganization } from "../../../../providers/CurrentOrganizationProvider";
import { patchOrganization } from "../../../../services/api/organizations";
import { useNavigate } from "react-router-dom";
import { organizationShowUrl } from "../../../../services/routes/urlBuilder";
import { RefundsFormSection } from "./RefundsFormSection";
import { TaxesAndFeesFormSection } from "../../../fees/TaxesAndFeesFormSection";

export const ManageTickets: React.FC = () => {
  const { organization, refetch } = useCurrentOrganization();
  const { control, handleSubmit } = useForm<Organization>({
    mode: "onBlur",
    defaultValues: organization,
  });
  const navigate = useNavigate();

  const onSubmit = async (data: Organization) => {
    const {
      is_refundable,
      num_days_refundable_ahead,
      include_fees_in_prices,
      include_taxes_in_prices,
      num_tax_basis_points,
    } = data;
    if (!organization) return;
    await patchOrganization(organization.id, {
      is_refundable,
      num_days_refundable_ahead,
      include_fees_in_prices,
      include_taxes_in_prices,
      num_tax_basis_points,
    });
    await refetch();
    navigate(organizationShowUrl(organization.slug));
  };

  return (
    <Panel>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack alignItems="stretch" gap={4}>
          <RefundsFormSection control={control} />
          <TaxesAndFeesFormSection
            control={control}
            currencyIsoCode={organization?.currency_iso_code}
            isOrganizationForm
          />
          <Button type="submit" variant="primary" size="lg" width="100%">
            Save
          </Button>
        </VStack>
      </form>
    </Panel>
  );
};
