import {
  Box,
  HStack,
  IconButton,
  Spacer,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

import { UserProfileEvent, useUserEvents } from "../../../hooks/useUserEvents";
import { useCurrentUser } from "../../../providers/CurrentUserProvider";
import { updateEventUser } from "../../../services/api/eventUsers";
import { DatetimeDirection } from "../../../types/datetime";
import { FilterButtonMenu } from "../../../components/FilterButtonMenu";
import { useSearchParams } from "react-router-dom";
import { EventCalendarCard } from "../../events/components/EventCalendarCard";
import { Panel } from "../../../components/Panel";
import { useEventsByDateInTimezone } from "../../../hooks/transformers/useEventsByDateInTimezone";
import EventCalendarGroupHeading from "../../events/components/EventCalendarGroupHeading";

interface EventsListProps {
  userId: string | undefined;
}

interface EventVisibiltiyToggleProps {
  event: UserProfileEvent;
  handleVisibility: (event: UserProfileEvent) => () => Promise<void>;
}

const EventVisibilityToggle: React.FC<EventVisibiltiyToggleProps> = ({
  event,
  handleVisibility,
}) => {
  return (
    <Box position="absolute" top={0} right={0}>
      <Tooltip
        label={event?.findable ? "Visible to everyone" : "Visible to just me"}
      >
        <IconButton
          variant="ghost"
          aria-label={
            event.findable ? "Visible to everyone" : "Visible to just me"
          }
          fontSize="24px"
          color="gray.800"
          icon={event.findable ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
          onClick={handleVisibility(event)}
        />
      </Tooltip>
    </Box>
  );
};

export const EventsList: React.FC<EventsListProps> = ({ userId }) => {
  const { currentUser } = useCurrentUser();
  const [searchParams] = useSearchParams();
  const initialDirection =
    searchParams.get("eventsDirection") === "future" ? "future" : "past";
  const [direction, setDirection] =
    useState<DatetimeDirection>(initialDirection);

  const { events, refetch: refetchEvents } = useUserEvents(userId, direction);

  const eventsByDay = useEventsByDateInTimezone(events ? events : []);

  const sortedEventsByDay = [...eventsByDay].sort((a, b) => {
    // Sort eventsByDay based on direction
    // For 'past' direction: most recent first (descending order)
    // For 'future' direction: upcoming first (ascending order is default from hook)
  const dateA = new Date(`${a.year}-${a.month}-${a.day}`);
    const dateB = new Date(`${b.year}-${b.month}-${b.day}`);
    
    return direction === "past" 
      ? dateB.getTime() - dateA.getTime() // descending for past events
      : dateA.getTime() - dateB.getTime(); // ascending for future events
  });

  const handleVisibility = (event: UserProfileEvent) => async () => {
    if (!event.event_user_id) return;
    await updateEventUser(event.event_user_id, { findable: !event.findable });
    await refetchEvents();
  };

  const handleSelectFilter = async (filter: DatetimeDirection) => {
    setDirection(filter);
  };

  return (
    <Panel background="rgba(255, 255, 255, 0.59)">
      <VStack width="100%" gap={1} alignItems="stretch">
        {currentUser?.id === userId && (
          <HStack width="100%">
            <Text size="lg" marginBottom={4}>
              {direction === "future" ? "Upcoming events" : "Past events"}
            </Text>
            <Spacer />
            <FilterButtonMenu<DatetimeDirection>
              options={[
                { label: "Upcoming", value: "future" },
                { label: "Past", value: "past" },
              ]}
              onSelect={handleSelectFilter}
            />
          </HStack>
        )}

        <VStack alignItems="stretch">
          {sortedEventsByDay.map((eventGroup, idx) => {
            return (
              <Box key={idx} mb={3}>
                <EventCalendarGroupHeading
                  day={eventGroup.day}
                  month={eventGroup.month}
                  year={eventGroup.year}
                />

                <VStack alignItems="stretch">
                  {eventGroup.events.map((eventData) => {
                    const event = eventData as UserProfileEvent;
                    return (
                      <Box position="relative">
                        <EventCalendarCard
                          event={event}
                          showOrganization={true}
                        />
                        {currentUser && currentUser.id === event.user_id && (
                          <EventVisibilityToggle
                            event={event}
                            handleVisibility={handleVisibility}
                          />
                        )}
                      </Box>
                    );
                  })}
                </VStack>
              </Box>
            );
          })}
        </VStack>
      </VStack>
    </Panel>
  );
};
