import {
  Heading,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Icon,
} from "@chakra-ui/react";
import { Waiver } from "../../types/waiver";
import { createWaiverSignature } from "../../services/api/waivers";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { WaiverSignature } from "../../types/waiver_signature";
import { getHtmlFromString } from "../../services/formatter";

interface SignWaiverModalProps {
  waiver: Waiver;
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
  waiverSignature?: WaiverSignature;
}

export const SignWaiverModal: React.FC<SignWaiverModalProps> = ({
  waiver,
  isOpen,
  onClose,
  onComplete,
  waiverSignature,
}) => {
  const handleSign = async () => {
    await createWaiverSignature(waiver.organization_id, waiver.id);
    onComplete();
  };

  const html = getHtmlFromString(waiver.content);

  if (!waiver) return null;

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading as="h2" size="xl">
              {waiver.name}
            </Heading>
          </ModalHeader>
          <ModalCloseButton margin={2}>
            <Icon as={AiOutlineCloseCircle} fontSize={36} />
          </ModalCloseButton>
          <ModalBody>
            <Text dangerouslySetInnerHTML={{ __html: html }} />
          </ModalBody>

          <ModalFooter>
            <Button
              variant="primary"
              width="100%"
              size="lg"
              marginBottom={12}
              onClick={handleSign}
              isDisabled={!!waiverSignature}
            >
              {waiverSignature ? "Signed" : "Sign"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
