import { useCallback, useEffect, useState } from "react";
import { useCurrentUser } from "../../../providers/CurrentUserProvider";
import {
  fetchUserPass,
  FetchUserPassResponse,
} from "../../../services/api/passes";
import { UserPass } from "../../../types/user_passes";
import { Pass } from "../../../types/passes";
import { Ticket } from "../../../types/ticket";

interface UseCurrentUserPass {
  userPass: UserPass | null;
  pass: Pass | null;
  ticket: Ticket | null;
}

export const useCurrentUserPass = (
  passId: string,
  eventId: string
): UseCurrentUserPass => {
  const { currentUser } = useCurrentUser();
  const [userPass, setUserPass] = useState<UserPass | null>(null);
  const [pass, setPass] = useState<Pass | null>(null);
  const [ticket, setTicket] = useState<Ticket | null>(null);

  const fetch = useCallback(async () => {
    if (!currentUser || !passId || !eventId) return;

    const { pass, ticket, user_pass }: FetchUserPassResponse =
      await fetchUserPass(passId, eventId);
    setUserPass(user_pass);
    setPass(pass);
    setTicket(ticket);
  }, [currentUser, passId, eventId]);

  useEffect(() => {
    const init = async () => {
      await fetch();
    };

    init();
  }, [fetch]);

  return { userPass, pass, ticket };
};
