import { Heading } from "@chakra-ui/react";
import { SubmitHandler } from "react-hook-form";
import { VianikoEvent } from "../../types/events";
import * as api from "../../services/api";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { EventForm } from "./components/forms/EventForm";
import { useCurrentOrganizationUser } from "../../providers/CurrentOrganizationUserProvider";
import {
  eventProgramUrl,
  eventTicketsUrl,
  homeUrl,
  organizationShowUrl,
} from "../../services/routes/urlBuilder";
import {
  hasPermission,
  PERMISSION_MANAGE_EVENTS,
} from "../../services/permissions";
import { RecurringEvent } from "../../types/recurring_events";
import { PageHeader } from "../../components/PageHeader";
import { PageBreadcrumb } from "../../components/PageBreadcrumb";
import { useOrganization } from "../../providers/CurrentOrganizationProvider";

export const NewEventPage: React.FC = () => {
  const navigate = useNavigate();
  const { organizationId } = useParams();
  const [searchParams] = useSearchParams();
  const parentEventIdParam = searchParams.get("parentEventId");
  const { currentOrganizationUser } = useCurrentOrganizationUser();

  const { organization } = useOrganization(organizationId);

  const handleSubmit: SubmitHandler<VianikoEvent & RecurringEvent> = async (
    data
  ) => {
    if (data.rrule) {
      const newRecurringEvent = await api.recurringEvents.createRecurringEvent(
        data
      );

      const [firstRecurringEvent] =
        await api.recurringEvents.fetchEventsOfRecurringEvent(
          newRecurringEvent.id
        );

      if (firstRecurringEvent) {
        navigate(eventTicketsUrl(firstRecurringEvent.id));
      } else {
        navigate(
          organization ? organizationShowUrl(organization.slug) : homeUrl()
        );
      }
    } else {
      const event = await api.events.createEvent(data);
      if (event.parent_event_id) {
        navigate(eventProgramUrl(event.parent_event_id));
      } else {
        navigate(eventTicketsUrl(event.id));
      }
    }
  };

  if (
    !!organization &&
    !currentOrganizationUser?.is_owner &&
    !hasPermission(
      currentOrganizationUser?.permissions,
      PERMISSION_MANAGE_EVENTS
    )
  )
    return null;

  return (
    <>
      <PageHeader
        breadcrumbs={
          organization ? (
            <PageBreadcrumb
              to={organizationShowUrl(organization.slug)}
              label={organization.name}
            />
          ) : (
            <PageBreadcrumb to={homeUrl()} label={"Home"} />
          )
        }
      />

      <Heading as="h1" size="lg" marginLeft={5} marginBottom={2}>
        Create Event
      </Heading>
      <EventForm
        onSubmit={handleSubmit}
        submitText="Create event"
        hideRecurring={!organization || !!parentEventIdParam}
        defaultValues={{
          currency_iso_code: organization?.currency_iso_code || "USD",
        }}
      />
    </>
  );
};
