export const testPhoneNumbers = [
  "+1(101) 123-4567",
  "+1(202) 123-4567",
  "+1(303) 123-4567",
  "+1(404) 123-4567",
  "+1(505) 123-4567",
  "+1(606) 123-4567",
  "+1(707) 123-4567",
  "+1(808) 123-4567",
  "+1(909) 123-4567",
];
