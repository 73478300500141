import {
  Icon,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import { BiCopy } from "react-icons/bi";

interface CopyToClipboardIconButtonProps {
  text: string;
}

export const CopyToClipboardIconButton: React.FC<CopyToClipboardIconButtonProps> = ({
  text,
}) => {
  const toast = useToast();

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    toast({
      description: "Link copied to clipboard",
      status: "success",
      duration: 2000,
    });
  };

  return (
    <IconButton 
      onClick={handleCopy}
      variant="primary"
      aria-label="Copy link"
      icon={<Icon as={BiCopy} />}
    />
  );
};
