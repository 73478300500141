import { useCallback, useEffect, useState } from "react";
import { Pass } from "../../../types/passes";
import { fetchPassPublic } from "../../../services/api/passes";

interface UsePassPublicResult {
  pass: Pass | undefined;
}

export const usePassPublic = (
  passId: string | undefined
): UsePassPublicResult => {
  const [pass, setPass] = useState<Pass | undefined>();

  const fetch = useCallback(async () => {
    if (!passId) return;

    const pass = await fetchPassPublic(passId);
    setPass(pass);
  }, [passId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { pass };
};
