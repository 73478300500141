import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  StyleProps,
} from "@chakra-ui/react";
import {
  Control,
  Controller,
  ControllerRenderProps,
  FieldError,
} from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { BiCalendar, BiTime } from "react-icons/bi";
import ZonedDateTime from "../../services/ZonedDateTime";

interface StyledDateTimeInputProps {
  name: string;
  control: Control<any>;
  defaultValue: string;
  timezone: string;
  onChange?: (value: string) => void;
  label?: string;
  error?: FieldError;
  rules?: any;
  dateOnly?: boolean;
  timeOnly?: boolean;
  styles?: StyleProps;
}

// data is of the format { date: string, time: string }
export const StyledDateTimeInput: React.FC<StyledDateTimeInputProps> = ({
  name,
  control,
  defaultValue,
  timezone,
  onChange,
  label,
  error,
  rules,
  dateOnly = false,
  timeOnly = false,
  styles: stylesProp,
}) => {
  const styles = {
    width: "100%",
    ...stylesProp,
  };

  const dateInputRef = useRef<HTMLInputElement>(null);
  const timeInputRef = useRef<HTMLInputElement>(null);
  const [keyPressedRecently, setKeyPressedRecently] = useState(false);
  const [dateInputFocusedRecently, setDateInputFocusedRecently] =
    useState(false);
  const [timeInputFocusedRecently, setTimeInputFocusedRecently] =
    useState(false);
  const [pointerPressedRecently, setPointerPressedRecently] = useState(false);

  const computeNewDate = (
    field: ControllerRenderProps,
    evt:
      | React.ChangeEvent<HTMLInputElement>
      | React.FocusEvent<HTMLInputElement, Element>
  ): string | undefined => {
    if (!evt.target.value) return;
    const zdt = ZonedDateTime.fromUtc(field.value || defaultValue, timezone);
    zdt.setDate(evt.target.value);
    return zdt.toISOUtc();
  };

  const computeNewTime = (
    field: ControllerRenderProps,
    evt:
      | React.ChangeEvent<HTMLInputElement>
      | React.FocusEvent<HTMLInputElement, Element>
  ): string | undefined => {
    if (!evt.target.value) return;
    const zdt = ZonedDateTime.fromUtc(field.value || defaultValue, timezone);
    zdt.setTime(evt.target.value);
    return zdt.toISOUtc();
  };

  const emitOnChangeIfNotKeypressedRecently = (
    newDateTime: string | undefined
  ) => {
    if (!keyPressedRecently) {
      if (onChange !== undefined && newDateTime !== undefined) {
        onChange(newDateTime);
      }
    }
  };

  const emitOnChange = (newDateTime: string | undefined) => {
    if (onChange !== undefined && newDateTime !== undefined && keyPressedRecently) {
      onChange(newDateTime);
    }
  };

  const timeDelayToClearRecentActionsInMS = 120;

  useEffect(() => {
    if (!keyPressedRecently) return;
    const timeout = setTimeout(() => {
      setKeyPressedRecently(false);
    }, timeDelayToClearRecentActionsInMS);
    return () => clearTimeout(timeout);
  }, [keyPressedRecently]);

  useEffect(() => {
    if (!pointerPressedRecently) return;
    const timeout = setTimeout(() => {
      setPointerPressedRecently(false);
    }, timeDelayToClearRecentActionsInMS);
    return () => clearTimeout(timeout);
  }, [pointerPressedRecently]);

  useEffect(() => {
    if (!timeInputFocusedRecently) return;
    const timeout = setTimeout(() => {
      setTimeInputFocusedRecently(false);
    }, timeDelayToClearRecentActionsInMS);
    return () => clearTimeout(timeout);
  }, [timeInputFocusedRecently]);

  useEffect(() => {
    if (!dateInputFocusedRecently) return;
    const timeout = setTimeout(() => {
      setDateInputFocusedRecently(false);
    }, timeDelayToClearRecentActionsInMS);
    return () => clearTimeout(timeout);
  }, [dateInputFocusedRecently]);

  return (
    <FormControl isInvalid={!!error} {...styles}>
      {label && (
        <FormLabel htmlFor={name} display="inline-block">
          {label}
        </FormLabel>
      )}
      <InputGroup>
        <Controller
          name={name}
          rules={rules}
          control={control}
          render={({ field }) => (
            <>
              <InputGroup
                variant="dateinput"
                visibility={timeOnly ? "hidden" : "visible"}
                width={timeOnly ? 0 : "auto"}
                maxWidth={192}
                minWidth={172}
                marginRight={timeOnly ? 0 : 4}
              >
                <Input
                  ref={dateInputRef}
                  name={`${name}.date`}
                  type="date"
                  variant="dateinput"
                  borderRight="none"
                  value={(() => {
                    const inputValue = field.value ? field.value : defaultValue;
                    const zdt = ZonedDateTime.fromUtc(inputValue, timezone);
                    return zdt.toDisplayDate();
                  })()}
                  onKeyDown={() => setKeyPressedRecently(true)}
                  onClick={() => {
                    if (dateInputFocusedRecently) {
                      dateInputRef.current?.showPicker();
                    }
                  }}
                  onChange={(e) => {
                    const iso = computeNewDate(field, e);
                    emitOnChangeIfNotKeypressedRecently(iso);
                    field.onChange(iso);
                  }}
                  onFocus={() => {
                    setDateInputFocusedRecently(true);
                  }}
                  onBlur={(e) => {
                    const iso = computeNewDate(field, e);
                    emitOnChange(iso);
                  }}
                />

                <InputRightAddon
                  onClick={() => {
                    if (dateInputRef.current) {
                      dateInputRef.current.focus();
                      dateInputRef.current.showPicker();
                    }
                  }}
                >
                  <BiCalendar />
                </InputRightAddon>
              </InputGroup>
              <InputGroup
                variant="dateinput"
                visibility={dateOnly ? "hidden" : "visible"}
                width={dateOnly ? 0 : "auto"}
                maxWidth={192}
              >
                <Input
                  ref={timeInputRef}
                  name={`${name}.time`}
                  type="time"
                  variant="dateinput"
                  borderRight="none"
                  value={(() => {
                    const inputValue = field.value ? field.value : defaultValue;
                    const zdt = ZonedDateTime.fromUtc(inputValue, timezone);
                    return zdt.toDisplayTime();
                  })()}
                  onKeyDown={() => setKeyPressedRecently(true)}
                  onClick={() => {
                    if (timeInputFocusedRecently) {
                      timeInputRef.current?.showPicker();
                    }
                  }}
                  onChange={(e) => {
                    const iso = computeNewTime(field, e);
                    emitOnChangeIfNotKeypressedRecently(iso);
                    field.onChange(iso);
                  }}
                  onFocus={() => {
                    setTimeInputFocusedRecently(true);
                  }}
                  onBlur={(e) => {
                    const iso = computeNewTime(field, e);
                    emitOnChange(iso);
                  }}
                />
                <InputRightAddon
                  onClick={() => {
                    if (timeInputRef.current) {
                      timeInputRef.current.focus();
                      timeInputRef.current.showPicker();
                    }
                  }}
                >
                  <BiTime />
                </InputRightAddon>
              </InputGroup>
            </>
          )}
        />
      </InputGroup>
      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};
