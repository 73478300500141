import { useEffect } from "react";
import { authUrl } from "../services/routes/urlBuilder";
import { useAuth } from "../providers/AuthProvider";
import { useLocation, useNavigate } from "react-router-dom";

export const useUnauthenticatedRedirect = (redirectTo?: string): void => {
  const { user, refreshing, initialized } = useAuth();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (initialized && !user && !refreshing) {
      navigate(redirectTo ?? authUrl(`${pathname}${search}`));
    }
  }, [initialized, user, refreshing, navigate, pathname, search, redirectTo]);
};
