import { Heading, Text } from "@chakra-ui/react";
import {
  DEFAULT_CURRENCY_ISO_CODE,
  priceInDollars,
} from "../../services/payments";
import { CURRENCY_CODE_TO_CURRENCY } from "../../services/currencies";
import { Elements } from "@stripe/react-stripe-js";
import { useOrganization } from "../../providers/CurrentOrganizationProvider";
import { useStripeAccount } from "../../hooks/useStripeAccount";
import { SubscriptionElementsContent } from "./SubscriptionElementsContent";
import { Pass } from "../../types/passes";

interface PassSubscriptionPaymentProps {
  pass: Pass;
}

export const PassSubscriptionPayment: React.FC<
  PassSubscriptionPaymentProps
> = ({ pass }) => {
  const { organization } = useOrganization(pass?.organization_id);
  const { stripe } = useStripeAccount(organization?.id);

  if (!pass || !stripe || !organization) return null;

  const totalPriceInCents = pass.total_price_in_cents;

  return (
    <>
      <Heading size="md" marginY={2}>
        Payment for {pass.name}
      </Heading>
      <Text marginY={4}>
        {priceInDollars(
          organization.currency_iso_code ?? DEFAULT_CURRENCY_ISO_CODE,
          totalPriceInCents
        )}{" "}
        {
          CURRENCY_CODE_TO_CURRENCY[
            organization.currency_iso_code ?? DEFAULT_CURRENCY_ISO_CODE
          ].code
        }
      </Text>
      <Elements
        stripe={stripe}
        options={{
          mode: "subscription",
          amount: totalPriceInCents,
          currency: (
            organization.currency_iso_code ?? DEFAULT_CURRENCY_ISO_CODE
          ).toLowerCase(),
        }}
      >
        <SubscriptionElementsContent pass={pass} />
      </Elements>
    </>
  );
};
