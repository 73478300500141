import { useForm } from "react-hook-form";
import { Waiver } from "../../types/waiver";
import { StyledRegisteredInput } from "../../components/forms/StyledRegisteredInput";
import { Button, VStack } from "@chakra-ui/react";
import { StyledTextarea } from "../../components/forms/StyledTextarea";
import { Panel } from "../../components/Panel";

interface WaiverFormProps {
  onSubmit: (waiver: Waiver) => void;
  values?: Waiver;
}

export const WaiverForm: React.FC<WaiverFormProps> = ({
  onSubmit: onSubmitProp,
  values,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Waiver>({ values, mode: "onBlur" });

  const onSubmit = (waiver: Waiver) => {
    onSubmitProp(waiver);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Panel>
        <VStack>
          <StyledRegisteredInput
            name="name"
            label="Give waiver a name"
            type="text"
            register={register}
            options={{ required: "This field is required" }}
            error={errors.name}
          />
          <StyledTextarea
            name="content"
            label="Waiver contents"
            register={register}
            options={{ required: "This field is required" }}
            error={errors.content}
            textareaProps={{
              minHeight: "200px",
            }}
          />
        </VStack>
      </Panel>
      <Button
        type="submit"
        variant="primary"
        size="lg"
        width="100%"
        marginY={4}
      >
        Save
      </Button>
    </form>
  );
};
