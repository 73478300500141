import { Control, FieldValues, useController, Path } from "react-hook-form";
import { TaxesAndFeesFormSection } from "./TaxesAndFeesFormSection";
import { Organization } from "../../types/organization";
import {
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { useEffect } from "react";

interface OverrideTaxesAndFeesFormSectionProps<T extends FieldValues> {
  currencyIsoCode: string;
  control: Control<T>;
  organization: Organization;
}

export const OverrideTaxesAndFeesFormSection = <T extends FieldValues>({
  currencyIsoCode,
  control,
  organization,
}: OverrideTaxesAndFeesFormSectionProps<T>) => {
  const {
    field: { value: overridesFees, onChange: onChangeOverridesFees },
  } = useController({
    name: "overrides_fees_and_taxes" as Path<T>,
    control,
  });

  const {
    field: { onChange: onChangeIncludeFees },
  } = useController({
    name: "include_fees_in_prices" as Path<T>,
    control,
  });

  const {
    field: { onChange: onChangeIncludeTaxes },
  } = useController({
    name: "include_taxes_in_prices" as Path<T>,
    control,
  });

  const {
    field: { onChange: onChangeNumTaxBasisPoints },
  } = useController({
    name: "num_tax_basis_points" as Path<T>,
    control,
  });

  useEffect(() => {
    if (!overridesFees) {
      onChangeIncludeFees(organization.include_fees_in_prices);
      onChangeIncludeTaxes(organization.include_taxes_in_prices);
      onChangeNumTaxBasisPoints(organization.num_tax_basis_points);
    }
  }, [
    overridesFees,
    organization,
    onChangeIncludeFees,
    onChangeIncludeTaxes,
    onChangeNumTaxBasisPoints,
  ]);

  return (
    <VStack alignItems="stretch" gap={4} width="100%">
      <FormControl>
        <HStack>
          <Checkbox
            isChecked={!overridesFees}
            onChange={(e) => onChangeOverridesFees(!e.target.checked)}
          />
          <FormLabel margin={0}>
            Use default fees and tax settings for this organization
          </FormLabel>
        </HStack>
      </FormControl>

      {overridesFees && (
        <TaxesAndFeesFormSection<T>
          control={control}
          currencyIsoCode={currencyIsoCode}
        />
      )}
    </VStack>
  );
};
