import { useNavigate } from "react-router-dom";
import { createWaiver } from "../../services/api/waivers";
import { Waiver } from "../../types/waiver";
import { WaiverForm } from "./WaiverForm";
import { organizationShowUrl } from "../../services/routes/urlBuilder";
import { useCurrentOrganization } from "../../providers/CurrentOrganizationProvider";

export const NewWaiver: React.FC = () => {
  const { organization } = useCurrentOrganization();
  const navigate = useNavigate();

  const handleSubmit = async (waiver: Waiver) => {
    if (!organization) return;

    await createWaiver(organization.id, waiver);

    navigate(organizationShowUrl(organization.slug));
  };

  return <WaiverForm onSubmit={handleSubmit} />;
};
