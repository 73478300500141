import { Organization } from "../../../types/organization";
import { adminManageOrganizationPayoutUrl } from "../../../services/routes/urlBuilder";
import { AdminActionBadge } from "../../../components/AdminActionBadge";

interface OrganizationSetupPaymentsBadgeProps {
  organization: Organization;
  canConnectStripe: boolean;
}

export const OrganizationSetupPaymentsBadge: React.FC<
  OrganizationSetupPaymentsBadgeProps
> = ({ organization, canConnectStripe }) => {
  if (!!organization.stripe_account_id) {
    return null;
  }

  return (
    <AdminActionBadge
      alertText="Payments disabled"
      actionText="Setup payments"
      actionUrl={adminManageOrganizationPayoutUrl(organization.slug)}
      hideAction={!canConnectStripe}
    />
  );
};
