import { useState } from "react";
import { Box, Button, Heading, HStack, Portal, Spacer } from "@chakra-ui/react";
import { Pass } from "../../../../types/passes";

import { VianikoEvent } from "../../../../types/events";
import { useCurrentUserPass } from "../../../passes/hooks/useCurrentUserPass";
import { createTicketForPass } from "../../../../services/api/passes";
import { PassConfirmationModal } from "./PassConfirmationModal";
import { SignAllWaiversModals } from "../../../waivers/SignAllWaiversModals";
import { PurchasePassModal } from "../../../passes/components/PurchasePassModal";
import {
  eventShowUrl,
  passPaymentUrl,
} from "../../../../services/routes/urlBuilder";
import { Organization } from "../../../../types/organization";

interface EventPassItemProps {
  pass: Pass;
  event: VianikoEvent;
  organization: Organization;
  onComplete: () => void;
}

export const EventPassItem: React.FC<EventPassItemProps> = ({
  pass,
  event,
  organization,
  onComplete,
}) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isWaiverSigningOpen, setIsWaiverSigningOpen] = useState(false);

  const { userPass, ticket } = useCurrentUserPass(pass.id, event.id);

  const handleUsePass = async () => {
    setIsConfirmationModalOpen(true);
  };

  const handleConfirmUsePass = async () => {
    setIsConfirmationModalOpen(false);
    setIsWaiverSigningOpen(true);
  };

  const handleBuyPass = () => {
    setIsConfirmationModalOpen(true);
  };

  const handleConfirmBuyPass = () => {
    setIsConfirmationModalOpen(false);
    setIsWaiverSigningOpen(true);
  };

  const getWaiverCompleteCallback = async () => {
    if (userPass?.is_valid) {
      await createTicketForPass(pass.id, event.id);
    }

    onComplete();
  };

  return (
    <>
      {userPass?.is_valid && isConfirmationModalOpen && (
        <Portal>
          <PassConfirmationModal
            pass={pass}
            userPass={userPass}
            isOpen={isConfirmationModalOpen}
            onClose={() => setIsConfirmationModalOpen(false)}
            onConfirm={handleConfirmUsePass}
          />
        </Portal>
      )}

      {!userPass?.is_valid && isConfirmationModalOpen && (
        <Portal>
          <PurchasePassModal
            passId={pass.id}
            isOpen={isConfirmationModalOpen}
            onClose={() => setIsConfirmationModalOpen(false)}
            onConfirm={handleConfirmBuyPass}
          />
        </Portal>
      )}

      {isWaiverSigningOpen && (
        <Portal>
          <SignAllWaiversModals
            organization={organization}
            onClose={() => setIsWaiverSigningOpen(false)}
            onBeforeComplete={getWaiverCompleteCallback}
            redirectTo={
              userPass?.is_valid
                ? eventShowUrl(event.id, {
                    invite: true,
                    welcome: true,
                  })
                : passPaymentUrl(pass.id, event.id)
            }
          />
        </Portal>
      )}

      <HStack>
        <Box>
          <Heading size="sm" as="h4">
            {pass.name}
          </Heading>
        </Box>

        <Spacer />

        {!!ticket ? (
          <Button variant="primary" size="sm" isDisabled>
            Used
          </Button>
        ) : userPass?.is_valid ? (
          <Button variant="primary" size="sm" onClick={handleUsePass}>
            Use
          </Button>
        ) : (
          <Button variant="primary" size="sm" onClick={handleBuyPass}>
            Buy
          </Button>
        )}
      </HStack>
    </>
  );
};
