import {
  Button,
  Circle,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { useFriendshipRequests } from "../pages/events/hooks/useFriendshipRequests";
import { BiLogOut, BiUser, BiUserPlus } from "react-icons/bi";
import { Link as RouterLink } from "react-router-dom";
import { logoutUrl, userShowUrl } from "../services/routes/urlBuilder";
import { UserAvatar } from "./UserAvatar";
import { VianikoUser } from "../types/users";

interface UserAccountDropdownProps {
  user: VianikoUser;
}

export const UserAccountDropdown: React.FC<UserAccountDropdownProps> = ({
  user,
}) => {
  const { friendshipRequestIncomingByUserId } = useFriendshipRequests();
  const numFriendshipRequests = Object.keys(
    friendshipRequestIncomingByUserId || {}
  ).length;
  return (
    <>
      <Menu>
        <MenuButton as={Button} variant="unstyled" marginTop={2} padding={0}>
          <UserAvatar size="md" user={user} />
        </MenuButton>

        <MenuList>
          <MenuItem
            onClick={() => (window.location.href = userShowUrl(user.id))}
          >
            <Icon as={BiUser} fontSize="18px" />

            <Text marginLeft={2}>Profile</Text>
          </MenuItem>

          {numFriendshipRequests > 0 && (
            <MenuItem
              onClick={() =>
                (window.location.href = userShowUrl(user.id, {
                  tab: "friends",
                }))
              }
            >
              <Circle backgroundColor="red.500" size="18px">
                <Icon as={BiUserPlus} fontSize="14px" color="white" />
              </Circle>
              <Text marginLeft={2}>Friend requests</Text>
            </MenuItem>
          )}

          <Link as={RouterLink} to={logoutUrl()}>
            <MenuItem>
              <Icon as={BiLogOut} fontSize="18px" />
              <Text marginLeft={2}>Logout</Text>
            </MenuItem>
          </Link>
        </MenuList>
      </Menu>
    </>
  );
};
