import {
  Flex,
  Text,
  Spacer,
  Button,
  LinkBox,
  LinkOverlay,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { UserAvatar } from "../../../components/UserAvatar";
import { useFriendshipRequests } from "../hooks/useFriendshipRequests";
import { useCurrentUser } from "../../../providers/CurrentUserProvider";
import { ExtendedUser } from "../hooks/useAttendees";
import { useAllCurrentUserFriendships } from "../../../hooks/useAllCurrentUserFriendships";
import { Panel } from "../../../components/Panel";
import { VianikoEvent } from "../../../types/events";
import { userShowUrl } from "../../../services/routes/urlBuilder";

interface EventAttendeesProps {
  event: VianikoEvent;
  attendees: ExtendedUser[] | undefined;
  numAttendees: number;
  onRefundTickets?: () => void;
}

export const EventAttendees: React.FC<EventAttendeesProps> = ({
  event,
  attendees,
  numAttendees,
  onRefundTickets,
}) => {
  const { friendshipRequestOutgoingByUserId, create } = useFriendshipRequests();
  const { currentUser } = useCurrentUser();
  const { friendsByUserId } = useAllCurrentUserFriendships();

  const handleAddFriend = (userId: string) => async () => {
    await create(userId);
  };

  if (!attendees) return null;

  return (
    <Panel>
      <Flex gap={4} direction="column">
        {attendees?.map((attendee, index) => (
          <LinkBox key={attendee.id}>
            <Flex gap={2} align="center">
              <UserAvatar size="md" user={attendee} />
              <LinkOverlay as={RouterLink} to={userShowUrl(attendee.id)}>
                <Text flexGrow={4} whiteSpace="nowrap" noOfLines={1}>
                  {attendee.full_name}
                </Text>
              </LinkOverlay>
              <Spacer />

              {friendsByUserId[attendee.id] ? (
                <Button isDisabled>Friend</Button>
              ) : friendshipRequestOutgoingByUserId?.[attendee.id] ? (
                <Button isDisabled>Awaiting</Button>
              ) : attendee.id !== currentUser?.id ? (
                <Button
                  variant="primary"
                  onClick={handleAddFriend(attendee.id)}
                >
                  Add friend
                </Button>
              ) : null}
            </Flex>
          </LinkBox>
        ))}

        {numAttendees - attendees.length > 0 && (
          <Text size="sm">
            And {numAttendees - attendees.length} more people
          </Text>
        )}
      </Flex>
    </Panel>
  );
};
