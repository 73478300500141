import {
  Box,
  Button,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { Panel } from "../../../components/Panel";
import { TEXT_SECONDARY_COLOR } from "../../../services/theme/colors";
import {
  priceInDollars,
  DEFAULT_CURRENCY_ISO_CODE,
} from "../../../services/payments";
import { PassAboutText } from "../../organizations/components/PassAboutText";
import { usePassPublic } from "../hooks/usePassPublic";
import { useOrganization } from "../../../providers/CurrentOrganizationProvider";
import { FeesAndTaxesIndicator } from "../../../components/FeesAndTaxesIndicator";

interface PurchasePassModalProps {
  passId: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const PurchasePassModal: React.FC<PurchasePassModalProps> = ({
  passId,
  isOpen,
  onClose,
  onConfirm,
}) => {
  const { pass } = usePassPublic(passId);
  const { organization } = useOrganization(pass?.organization_id);

  if (!pass || !organization) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <HStack margin={4}>
            <Heading size="lg" as="h2">
              Buy a pass
            </Heading>
          </HStack>
        </ModalHeader>
        <ModalCloseButton marginTop={2} />
        {pass && (
          <ModalBody paddingTop={0}>
            <Panel>
              <HStack align="start">
                <Box>
                  <Heading as="h3" size="md">
                    {pass.name}
                  </Heading>

                  <HStack align="center" marginY={1}>
                    <Text size="md" color={TEXT_SECONDARY_COLOR}>
                      {priceInDollars(
                        organization.currency_iso_code ??
                          DEFAULT_CURRENCY_ISO_CODE,
                        pass.price_in_cents
                      )}
                    </Text>

                    <FeesAndTaxesIndicator
                      currencyIsoCode={
                        organization.currency_iso_code ??
                        DEFAULT_CURRENCY_ISO_CODE
                      }
                      feesInCents={pass.fees_in_cents}
                      taxesInCents={pass.taxes_in_cents}
                      includeFeesInPrices={pass.include_fees_in_prices}
                      includeTaxesInPrices={pass.include_taxes_in_prices}
                    />
                  </HStack>

                  <PassAboutText pass={pass} />

                  {pass.description && (
                    <Text size="md" color={TEXT_SECONDARY_COLOR}>
                      {pass.description}
                    </Text>
                  )}
                </Box>

                <Spacer />
              </HStack>
            </Panel>

            <Button
              onClick={onConfirm}
              variant="primary"
              size="lg"
              width="100%"
              marginTop={4}
              marginBottom={8}
            >
              Buy pass
            </Button>
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
};
