import {
  Box,
  HStack,
  Icon,
  LinkBox,
  LinkOverlay,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { Waiver } from "../../../types/waiver";
import { BiBookHeart } from "react-icons/bi";
import { waiverShowUrl } from "../../../services/routes/urlBuilder";
import { ManageWaiverMenu } from "./ManageWaiverMenu";
import { ButtonLink } from "../../../components/ButtonLink";
import { format } from "date-fns";
import { useWaiverSignature } from "../../../hooks/useWaiverSignature";
import { useCurrentOrganization } from "../../../providers/CurrentOrganizationProvider";

interface WaiverItemProps {
  waiver: Waiver;
  onDelete: () => void;
  canManage: boolean;
}

export const WaiverItem: React.FC<WaiverItemProps> = ({
  waiver,
  onDelete,
  canManage,
}) => {
  const { waiverSignature } = useWaiverSignature(waiver);

  const { organization } = useCurrentOrganization();

  if (!organization || (!!waiver.deleted_at && !waiverSignature && !canManage))
    return null;

  return (
    <LinkBox>
      <HStack>
        <Icon as={BiBookHeart} />
        <Box>
          <LinkOverlay
            as={RouterLink}
            to={waiverShowUrl(organization.slug, waiver.id)}
          >
            <Text size="md">{waiver.name}</Text>
          </LinkOverlay>
          <Text size="sm" variant="secondary" marginTop={0}>
            {waiver.deleted_at && canManage
              ? `Deleted ${format(waiver.deleted_at, "MM.dd.yyyy")}`
              : waiverSignature
              ? `Signed ${format(waiverSignature.created_at, "MM.dd.yyyy")}`
              : `Uploaded ${format(waiver.created_at, "MM.dd.yyyy")}`}
          </Text>
        </Box>
        <Spacer />
        {!waiver.deleted_at && (
          <>
            {canManage ? (
              <ManageWaiverMenu
                organizationId={waiver.organization_id}
                waiver={waiver}
                onDelete={onDelete}
              />
            ) : (
              <ButtonLink
                to={waiverShowUrl(organization.slug, waiver.id)}
                buttonProps={{
                  size: "sm",
                  variant: "primary",
                  isDisabled: !!waiverSignature,
                }}
              >
                {waiverSignature ? "Signed" : "Sign"}
              </ButtonLink>
            )}
          </>
        )}
      </HStack>
    </LinkBox>
  );
};
