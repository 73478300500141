import { Heading } from "@chakra-ui/react";
import { TicketTypeForm } from "../events/components/forms/TicketTypeForm";
import { useNavigate, useParams } from "react-router-dom";
import { eventShowUrl } from "../../services/routes/urlBuilder";
import { useTicketType } from "../../hooks/useTicketType";
import { useEvent } from "../../hooks/useEvent";
import { useOrganization } from "../../providers/CurrentOrganizationProvider";

export const EditTicketTypePage: React.FC = () => {
  const { eventId, ticketTypeId } = useParams();
  const { ticketType } = useTicketType(ticketTypeId);
  const { event } = useEvent(eventId);
  const { organization } = useOrganization(event?.organization_id);
  const navigate = useNavigate();

  const handleSubmit = () => {
    if (!eventId) return;

    navigate(eventShowUrl(eventId));
  };

  if (!event || !organization || !ticketType) return null;

  return (
    <>
      <Heading as="h1" size="lg">
        Edit ticket
      </Heading>
      <TicketTypeForm
        event={event}
        organization={organization}
        ticketType={ticketType}
        onSubmit={handleSubmit}
      />
    </>
  );
};
