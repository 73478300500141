import { Flex } from "@chakra-ui/react";
import React from "react";
import { BiBookHeart } from "react-icons/bi";
import { MdOutlinePeople } from "react-icons/md";
import { TiTicket } from "react-icons/ti";
import {
  LandingPageBlockData,
  LandingPageSection,
} from "./components/LandingPageSection";
import { LandingPageFeatureBlock } from "./components/LandingPageFeatureBlock";
import { getStaticImageUrl } from "../../services/images";
import { FeaturedOrganizations } from "../organizations/FeaturedOrganizations";

export const LandingPage: React.FC = () => {
  const sectionData: LandingPageBlockData[] = [
    {
      title: "Bring People Together",
      description: "The easiest way to run jams, classes, and festivals",
      buttonText: "Start now",
      image: getStaticImageUrl("/website/hero.webp"),
    },
    {
      title: "Your space to sell tickets, memberships, and passes",
      description: "Get set up in minutes, not weeks",
      buttonText: "Sign up",
      image: getStaticImageUrl("/website/red.webp"),
    },
    {
      title: "Build your IRL community",
      description:
        "Feather helps new people get to know everybody in your classes. Customers who connect keep coming back",
      image: getStaticImageUrl("/website/phone_1.webp"),
    },
    {
      title: "Replace drop-ins with devotees",
      description:
        "People already love you, and now they can show it. Feather helps your customers to spread the word - so every spot gets filled",
      buttonText: "Try it out",
      image: getStaticImageUrl("/website/phone_2.webp"),
    },
    {
      title: "Admin on auto-pilot",
      description:
        "Feather takes care of the hassle so you can focus on what you do best",
      image: getStaticImageUrl("/website/screenshots.webp"),
    },
  ];

  return (
    <>
      {sectionData.map((data, index) => (
        <LandingPageSection
          key={index}
          title={data.title}
          description={data.description}
          buttonText={data.buttonText}
          image={data.image}
          textArrangement={index % 2 === 0 ? "left" : "right"}
        />
      ))}

      <Flex justify="space-between" mx="10" my="10" wrap="wrap" gap={8}>
        <LandingPageFeatureBlock
          title="Free text blasts"
          description="Keep your attendees engaged"
          icon={<MdOutlinePeople />}
        />

        <LandingPageFeatureBlock
          title="Waivers for every guest"
          description="We've got you covered"
          icon={<BiBookHeart />}
        />

        <LandingPageFeatureBlock
          title="Check-ins, refunds, and exchanges"
          description="Effortless ticketing"
          icon={<TiTicket />}
        />
      </Flex>

      <LandingPageSection
        title="No monthly fees"
        description="Start getting paid in minutes"
        buttonText="Get started"
        image={getStaticImageUrl("/website/above_head.webp")}
        textArrangement="right"
      />

      <FeaturedOrganizations marginY={16} />
    </>
  );
};
