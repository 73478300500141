import { loadStripe, Stripe } from "@stripe/stripe-js";
import { getCurrencyByCode } from "./currencies";
import { PaymentType } from "../types/ticket_types";

export const DEFAULT_CURRENCY_ISO_CODE = "USD";

export const priceForTicketType = (
  paymentType: PaymentType,
  priceInCents: number,
  currencyIsoCode: string,
  precision?: number
): string => {
  if (paymentType === "free") {
    return "Free";
  } else {
    return priceInDollars(currencyIsoCode, priceInCents, precision);
  }
};

export const priceInDollars = (
  currencyIsoCode: string,
  priceInCents?: number,
  precision?: number
): string => {
  if (priceInCents === undefined) return "";

  return `${getCurrencyByCode(currencyIsoCode)?.symbol}${(
    priceInCents / 100
  ).toFixed(precision === undefined ? 2 : precision)}`;
};

export const getStripeFeeDescription = (
  currencyIsoCode: string | undefined
): string => {
  switch (currencyIsoCode) {
    case "MXN":
      return "3.6% + MXN$3.00";
    case "USD":
    case "CAD":
      return "2.9% + $0.30";
    case "EUR":
      return "1.5% + 0.25€";
    case "GBP":
      return "1.5% + 20p";
    case "AUD":
      return "1.7% + A$0.30";
    case "SGD":
      return "3.4% + S$0.50";
    default:
      return "2.9% + $0.30";
  }
};

export const initializeStripeAccount = async (
  connectedAccountId: string
): Promise<Stripe | null> => {
  if (!process.env.REACT_APP_STRIPE_PUB_KEY) {
    throw new Error("REACT_APP_STRIPE_PUB_KEY is required");
  }

  return await loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY, {
    stripeAccount: connectedAccountId,
  });
};
