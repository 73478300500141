import { useOutletContext } from "react-router-dom";
import { EventOverview } from "../components/EventOverview";
import { EventShowOutletContext } from ".";

export const EventInfo = () => {
  const { event, organization, canManagePayments } =
    useOutletContext<EventShowOutletContext>();
  return (
    <EventOverview
      event={event}
      organization={organization}
      canManageRefunds={canManagePayments}
    />
  );
};
