import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Spacer,
  Text,
} from "@chakra-ui/react";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";
import {
  FieldError,
  RegisterOptions,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import { TEXT_SECONDARY_COLOR } from "../../services/theme/colors";

export interface AutocompleteOption {
  id: string;
  label: string;
}

interface AutocompleteInputProps {
  name: string;
  label?: string;
  selectOptions: AutocompleteOption[];
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  register: UseFormRegister<any>;
  error?: FieldError;
  registerOptions?: RegisterOptions;
  setValue?: UseFormSetValue<any>;
  defaultValue?: string;
  clearErrors?: UseFormClearErrors<any>;
  onSelect?: (value: string) => void;
  placeholder?: string;
}

export const AutocompleteInput: React.FC<AutocompleteInputProps> = ({
  name,
  label,
  selectOptions,
  onKeyUp,
  register,
  error,
  registerOptions,
  setValue,
  defaultValue,
  clearErrors,
  onSelect,
  placeholder,
}) => {
  const { required } = registerOptions || {};

  return (
    <FormControl isInvalid={!!error}>
      {(label || !required) && (
        <HStack>
          {label && <FormLabel>{label}</FormLabel>}
          <Spacer />
          {!required && (
            <Text size="md" color={TEXT_SECONDARY_COLOR}>
              Optional
            </Text>
          )}
        </HStack>
      )}
      <AutoComplete
        openOnFocus
        onChange={(val) => {
          clearErrors && clearErrors(name);
          setValue && setValue(name, val, { shouldDirty: true });
          onSelect && onSelect(val);
        }}
        defaultValue={defaultValue}
      >
        <AutoCompleteInput
          {...register(name, registerOptions)}
          onKeyUp={onKeyUp}
          backgroundColor="white"
          border="2px solid"
          size="lg"
          placeholder={placeholder}
        />
        <AutoCompleteList>
          {selectOptions.map((selectOption) => (
            <AutoCompleteItem
              key={selectOption.id}
              value={selectOption.id}
              label={selectOption.label}
            >
              {selectOption.label}
            </AutoCompleteItem>
          ))}
        </AutoCompleteList>
      </AutoComplete>
      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};
