import { Box, Heading, HStack, Spacer, Text } from "@chakra-ui/react";
import { priceInDollars } from "../../../services/payments";
import { TEXT_SECONDARY_COLOR } from "../../../services/theme/colors";
import { Pass } from "../../../types/passes";
import { ManagePassInfoMenu } from "../../passes/components/ManagePassInfoMenu";
import { PassAboutText } from "./PassAboutText";
import { Organization } from "../../../types/organization";
import { DEFAULT_CURRENCY_ISO_CODE } from "../../../services/payments";
import { FeesAndTaxesIndicator } from "../../../components/FeesAndTaxesIndicator";

interface AdminPassInfoProps {
  pass: Pass;
  organization: Organization;
  onDelete: () => void;
}

export const AdminPassInfo: React.FC<AdminPassInfoProps> = ({
  pass,
  organization,
  onDelete,
}) => {
  const handleDelete = () => {
    onDelete();
  };

  return (
    <>
      <HStack align="start">
        <Box>
          <Heading as="h3" size="md">
            {pass.name}
          </Heading>

          <HStack align="center" marginY={1}>
            <Text size="md" color={TEXT_SECONDARY_COLOR}>
              {priceInDollars(
                organization.currency_iso_code ?? DEFAULT_CURRENCY_ISO_CODE,
                pass.price_in_cents
              )}
            </Text>

            <FeesAndTaxesIndicator
              currencyIsoCode={
                organization.currency_iso_code ?? DEFAULT_CURRENCY_ISO_CODE
              }
              feesInCents={pass.fees_in_cents}
              taxesInCents={pass.taxes_in_cents}
              includeFeesInPrices={pass.include_fees_in_prices}
              includeTaxesInPrices={pass.include_taxes_in_prices}
            />
          </HStack>

          <PassAboutText pass={pass} />

          {pass.description && (
            <Text size="md" color={TEXT_SECONDARY_COLOR}>
              {pass.description}
            </Text>
          )}
        </Box>

        <Spacer />

        <HStack>
          <ManagePassInfoMenu
            organization={organization}
            pass={pass}
            onDelete={handleDelete}
          />
        </HStack>
      </HStack>
    </>
  );
};
