import { useOutletContext } from "react-router-dom";
import { EventShowOutletContext } from ".";
import { EventTickets } from "../components/EventTickets";

export const TicketsTab = () => {
  const {
    showTickets,
    canManageTickets,
    canManagePasses,
    event,
    organization,
    tickets,
    ticketTypes,
    canManageOrganization,
    onBuyTickets,
  } = useOutletContext<EventShowOutletContext>();

  return showTickets ? (
    <EventTickets
      event={event}
      organization={organization}
      tickets={tickets}
      ticketTypes={ticketTypes}
      canManageTickets={canManageTickets}
      canManagePasses={canManagePasses}
      canManageOrganization={canManageOrganization}
      onBuyTickets={onBuyTickets}
    />
  ) : null;
};
