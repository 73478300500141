import { Outlet, useNavigate } from "react-router-dom";
import { useCurrentOrganizationUser } from "../../../providers/CurrentOrganizationUserProvider";
import {
  adminManageOrganizationUsersUrl,
  adminManageOrganizationPayoutUrl,
  organizationShowUrl,
  adminManageOrganizationRefundsUrl as adminManageOrganizationTicketsUrl,
} from "../../../services/routes/urlBuilder";
import { useCurrentOrganization } from "../../../providers/CurrentOrganizationProvider";
import { useEffect, useMemo } from "react";
import { Box, Flex, Heading, HStack, Spacer } from "@chakra-ui/react";
import { SidebarContent, SidebarLinkItem } from "./layout/SidebarContent";
import { MdPeople } from "react-icons/md";
import { BiMoneyWithdraw } from "react-icons/bi";
import { MobileMenu } from "./layout/MobileMenu";
import { PageBreadcrumb } from "../../../components/PageBreadcrumb";
import { RiRefund2Line } from "react-icons/ri";

export const OrganizationAdminWrapper: React.FC = () => {
  const { currentOrganizationUser, initialized: orgUserInitialized } =
    useCurrentOrganizationUser();
  const { organization } = useCurrentOrganization();
  const navigate = useNavigate();

  useEffect(() => {
    if (!organization || !orgUserInitialized) return;

    if (currentOrganizationUser && !currentOrganizationUser?.is_owner) {
      navigate(organizationShowUrl(organization!.slug));
    }
  }, [currentOrganizationUser, organization, navigate, orgUserInitialized]);

  const linkItems: SidebarLinkItem[] = useMemo(() => {
    if (!organization) return [];

    return [
      {
        name: "Manage admins",
        icon: MdPeople,
        to: adminManageOrganizationUsersUrl(organization.slug),
      },
      {
        name: "Manage payouts",
        icon: BiMoneyWithdraw,
        to: adminManageOrganizationPayoutUrl(organization.slug),
      },
      {
        name: "Manage tickets",
        icon: RiRefund2Line,
        to: adminManageOrganizationTicketsUrl(organization.slug),
      },
    ];
  }, [organization]);

  if (!organization) return null;

  return (
    <>
      <HStack height={12}>
        <PageBreadcrumb
          to={organizationShowUrl(organization.slug)}
          label={organization.name}
        />
        <Spacer />
        <MobileMenu
          display={{ base: "block", md: "none" }}
          linkItems={linkItems}
        />
      </HStack>

      <Heading as="h1" size="lg" marginLeft={5} marginBottom={2}>
        Organization Settings
      </Heading>

      <Flex width="100%" gap={1}>
        <SidebarContent
          display={{ base: "none", md: "block" }}
          linkItems={linkItems}
          minWidth={60}
        />

        <Box flexGrow={1}>
          <Outlet />
        </Box>
      </Flex>
    </>
  );
};
