import { Box, Heading, StyleProps, VStack } from "@chakra-ui/react";
import { useFeaturedOrganizations } from "../../hooks/useFeaturedOrganizations";
import { OrganizationListThemeCard } from "./components/OrganizationListThemeCard";

interface FeaturedOrganizationsProps extends StyleProps {
  headerSize?: "xl" | "sm";
}

export const FeaturedOrganizations: React.FC<FeaturedOrganizationsProps> = ({
  headerSize = "xl",
  ...styleProps
}) => {
  const { organizations } = useFeaturedOrganizations();

  return organizations.length > 0 ? (
    <Box {...styleProps}>
      <VStack gap={1} maxW="2xl" marginX="auto">
        <Heading size={headerSize} as="h3" margin={4} alignSelf="start">
          Featured organizations
        </Heading>
        {organizations.map((organization) => (
          <OrganizationListThemeCard
            key={organization.id}
            organization={organization}
          />
        ))}
      </VStack>
    </Box>
  ) : null;
};
