import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Spacer,
  Text,
  Textarea,
  TextareaProps,
} from "@chakra-ui/react";
import { FieldError, RegisterOptions, UseFormRegister } from "react-hook-form";
import { TEXT_SECONDARY_COLOR } from "../../services/theme/colors";

interface StyledTextareaProps {
  name: string;
  register: UseFormRegister<any>;
  label?: string;
  error?: FieldError;
  options?: RegisterOptions;
  textareaProps?: TextareaProps;
}

export const StyledTextarea: React.FC<StyledTextareaProps> = ({
  name,
  register,
  label,
  error,
  options,
  textareaProps,
}) => {
  const isRequired = options?.required;

  return (
    <FormControl isInvalid={!!error} width="100%">
      {(label || !isRequired) && (
        <HStack justify="center" marginBottom={2}>
          {label && (
            <FormLabel htmlFor={name} margin={0}>
              <Text size="md">{label}</Text>
            </FormLabel>
          )}
          <Spacer />
          {!isRequired && (
            <Text size="md" color={TEXT_SECONDARY_COLOR}>
              Optional
            </Text>
          )}
        </HStack>
      )}
      <Textarea
        backgroundColor="white"
        border="2px solid"
        borderRadius="24px"
        {...textareaProps}
        {...register(name, options)}
      />
      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};
