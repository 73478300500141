import { MenuItem, Link, Icon, Text, Portal } from "@chakra-ui/react";
import { BiCog, BiDoorOpen, BiPencil, BiTrashAlt } from "react-icons/bi";
import { RiTeamFill } from "react-icons/ri";
import { NavLink, useNavigate } from "react-router-dom";
import {
  authUrl,
  organizationEditUrl,
  organizationShowUrl,
  adminManageOrganizationUsersUrl,
} from "../../../services/routes/urlBuilder";
import { DeleteConfirmationModal } from "../../../components/DeleteConfirmationModal";
import { useState } from "react";
import { deleteOrganization } from "../../../services/api/organizations";
import { useCurrentOrganizationUser } from "../../../providers/CurrentOrganizationUserProvider";
import { updateOrganizationUserStatus } from "../../../services/api/organizationUsers";
import { useCurrentUser } from "../../../providers/CurrentUserProvider";
import { organizationUsers } from "../../../services/api";
import {
  hasPermission,
  PERMISSION_MANAGE_ORGANIZATION,
} from "../../../services/permissions";
import { Organization } from "../../../types/organization";

interface ManageOrganizationMenuListItemsProps {
  organization: Organization;
  onDelete: () => void;
  onLeave: () => void;
}

export const ManageOrganizationMenuListItems: React.FC<
  ManageOrganizationMenuListItemsProps
> = ({ organization, onDelete, onLeave }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { currentOrganizationUser, refetch: refetchCurrentOrganizationUser } =
    useCurrentOrganizationUser();

  const { currentUser } = useCurrentUser();
  const navigate = useNavigate();

  const joinCommunity = async () => {
    if (currentUser) {
      await organizationUsers.create(organization.id);
      refetchCurrentOrganizationUser();
    } else {
      navigate(authUrl(organizationShowUrl(organization.slug)));
    }
  };

  const handleDelete = async () => {
    await deleteOrganization(organization.id);
    setIsOpen(false);
    await onDelete();
  };

  const handleLeave = async () => {
    if (!currentOrganizationUser) return;
    await updateOrganizationUserStatus(currentOrganizationUser.id, "inactive");
    onLeave();
  };

  return (
    <>
      {currentOrganizationUser?.is_owner && (
        <>
          <Link
            as={NavLink}
            to={adminManageOrganizationUsersUrl(organization.id)}
          >
            <MenuItem>
              <Icon as={BiCog} marginRight={2} />
              <Text size="md">Settings</Text>
            </MenuItem>
          </Link>
          <Link onClick={() => setIsOpen(true)}>
            <MenuItem>
              <Icon as={BiTrashAlt} marginRight={2} />
              <Text size="md">Delete organization</Text>
            </MenuItem>
          </Link>
        </>
      )}

      {currentOrganizationUser &&
      (currentOrganizationUser.is_owner ||
        hasPermission(
          currentOrganizationUser.permissions,
          PERMISSION_MANAGE_ORGANIZATION
        )) ? (
        <Link as={NavLink} to={organizationEditUrl(organization.slug)}>
          <MenuItem>
            <Icon as={BiPencil} marginRight={2} />
            <Text size="md">Edit organization</Text>
          </MenuItem>
        </Link>
      ) : currentOrganizationUser ? (
        <Link onClick={handleLeave}>
          <MenuItem>
            <Icon as={BiDoorOpen} marginRight={2} />
            <Text size="md">Leave organization</Text>
          </MenuItem>
        </Link>
      ) : (
        <Link as={NavLink} onClick={joinCommunity}>
          <MenuItem>
            <Icon as={RiTeamFill} marginRight={2} />

            <Text size="md">Join organization</Text>
          </MenuItem>
        </Link>
      )}

      <Portal>
        <DeleteConfirmationModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          onDelete={handleDelete}
        />
      </Portal>
    </>
  );
};
