import {
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Link,
  Icon,
  Text,
} from "@chakra-ui/react";
import { BiDotsHorizontalRounded, BiTrashAlt } from "react-icons/bi";
import { Waiver } from "../../../types/waiver";
import { DeleteConfirmationModal } from "../../../components/DeleteConfirmationModal";
import { useState } from "react";
import { deleteWaiver } from "../../../services/api/waivers";

interface ManageWaiverMenuProps {
  organizationId: string;
  waiver: Waiver;
  onDelete: () => void;
}

export const ManageWaiverMenu: React.FC<ManageWaiverMenuProps> = ({
  organizationId,
  waiver,
  onDelete,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleDelete = async () => {
    await deleteWaiver(organizationId, waiver.id);
    setIsOpen(false);
    await onDelete();
  };

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<Icon as={BiDotsHorizontalRounded} />}
          variant="ghost"
        />
        <MenuList>
          <Link onClick={() => setIsOpen(true)}>
            <MenuItem>
              <Icon as={BiTrashAlt} marginRight={2} />
              <Text size="md">Delete Waiver</Text>
            </MenuItem>
          </Link>
        </MenuList>
      </Menu>
      <DeleteConfirmationModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onDelete={handleDelete}
      />
    </>
  );
};
