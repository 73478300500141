import { SubmitHandler } from "react-hook-form";
import { Organization } from "../../types/organization";
import { Box } from "@chakra-ui/react";
import { createOrganization } from "../../services/api/organizations";
import { useNavigate } from "react-router-dom";
import { OrganizationForm } from "./components/OrganizationForm";
import { organizationShowUrl } from "../../services/routes/urlBuilder";

export const NewOrganization: React.FC = () => {
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<Organization> = async (data) => {
    const organization = await createOrganization(data);
    navigate(organizationShowUrl(organization.slug));
  };

  return (
    <Box width="100%">
      <OrganizationForm onSubmit={onSubmit} />
    </Box>
  );
};
