import { Organization } from "../../types/organization";
import axiosInstance from "./axiosInstance";

export const fetchOrganization = async (organizationIdOrSlug: string) => {
  const result = await axiosInstance.get(
    `/public/organizations/${organizationIdOrSlug}`
  );
  return result.data;
};

export const PAGE_SIZE_ORGANIZATION_EVENTS = 24;
export const fetchOrganizationEvents = async (
  id: string,
  options?: {
    lastEventId?: string;
    startAt?: string;
  }
) => {
  const { lastEventId, startAt } = options || {};

  if (lastEventId && startAt)
    throw new Error("Only one of lastEventId or startAt can be specified");

  const params = new URLSearchParams();

  if (lastEventId) params.append("lastEventId", lastEventId);
  if (startAt) params.append("startAt", startAt);

  const result = await axiosInstance.get(
    `/public/organizations/${id}/events?${params.toString()}`
  );
  return result.data;
};

export const fetchOrganizationUsers = async (id: string) => {
  const result = await axiosInstance.get(`/organizations/${id}/users`);
  return result.data;
};

export const fetchNextEvent = async (id: string) => {
  const result = await axiosInstance.get(
    `/public/organizations/${id}/next_event`
  );
  return result.data;
};

export const createOrganization = async (data: Organization) => {
  const result = await axiosInstance.post("/organizations", data);
  return result.data;
};

export const updateOrganization = async (data: Organization) => {
  const result = await axiosInstance.put(`/organizations/${data.id}`, data);
  return result.data;
};

export const patchOrganization = async (
  organizationId: string,
  data: Partial<Organization>
) => {
  const result = await axiosInstance.patch(
    `/organizations/${organizationId}`,
    data
  );
  return result.data;
};

export const deleteOrganization = async (id: string) => {
  await axiosInstance.delete(`/organizations/${id}`);
};

export const inviteUserToOrganization = async (
  organizationId: string,
  userId: string
) => {
  const result = await axiosInstance.post(
    `/organizations/${organizationId}/invite`,
    {
      user_id: userId,
    }
  );
  return result.data;
};

export const fetchFeaturedOrganizations = async () => {
  const result = await axiosInstance.get("/public/organizations/featured");
  return result.data;
};

export const patchOrganizationUserPermissions = async (
  organizationId: string,
  userId: string,
  permissions: string | null
) => {
  const result = await axiosInstance.patch(
    `/organizations/${organizationId}/users/${userId}/permissions`,
    {
      permissions,
    }
  );
  return result.data;
};

export const fetchAdminOrganizationUsers = async (organizationId: string) => {
  const result = await axiosInstance.get(
    `/organizations/${organizationId}/organization_users_admin`
  );
  return result.data;
};

export const fetchNonRecurringEvents = async (organizationId: string) => {
  const result = await axiosInstance.get(
    `/organizations/${organizationId}/non_recurring_events`
  );
  return result.data;
};
