import {
  Box,
  Button,
  HStack,
  Icon,
  Image,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

import { VianikoEvent } from "../../../types/events";
import { toZonedTime } from "date-fns-tz";
import { format } from "date-fns";
import {
  eventShowUrl,
  eventTicketsUrl,
  organizationShowUrl,
} from "../../../services/routes/urlBuilder";
import { BiTime, BiUser, BiWorld } from "react-icons/bi";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { useInstructor } from "../../../hooks/useInstructor";
import { getImageUrl } from "../../../services/images";
import { isEventSingleDay } from "./EventThemeCard";
import { useOrganization } from "../../../providers/CurrentOrganizationProvider";
import { getHtmlFromString } from "../../../services/formatter";
import { useEventTickets } from "../../../hooks/useEventTickets";

interface EventCalendarCardProps {
  event: VianikoEvent;
  showOrganization?: boolean;
}

export const EventCalendarCard: React.FC<EventCalendarCardProps> = ({
  event,
  showOrganization = false,
}) => {
  const [descriptionVisible, setDescriptionVisible] = useState(false);

  // TODO fix - this results in many queries
  const { organization } = useOrganization(event.organization_id);

  const { tickets } = useEventTickets(event.id);

  const instructor = useInstructor(event.instructor_id);

  const startAt = toZonedTime(event.start_at, event.iana_timezone);
  const endAt = toZonedTime(event.end_at, event.iana_timezone);

  let startTimeString = format(startAt, "h:mmaaa");
  let endTimeString = format(endAt, "h:mmaaa");

  let longformEventDate = format(startAt, "EEEE MMMM d");

  const isSingleDayEvent = isEventSingleDay(startAt, endAt);
  if (!isSingleDayEvent) {
    longformEventDate = `${format(startAt, "EEEE MMMM d")} - ${format(
      endAt,
      "EEEE MMMM d"
    )}`;
  }

  // Check if the event has already started or is in the past relative to now
  const isEventOver = toZonedTime(new Date(), event.iana_timezone) > endAt;

  const toggleDescriptionVisibility = () => {
    setDescriptionVisible(!descriptionVisible);
  };

  const html = getHtmlFromString(event.description);

  return (
    <Box
      key={event.id}
      border="1px solid black"
      borderRadius={10}
      backgroundColor="white"
      padding={2}
      position="relative"
    >
      <VStack align="stretch" spacing={0} position="relative">
        <HStack mb={showOrganization ? 0 : 1}>
          {event.photo_id && (
            <Image
              src={getImageUrl(event.photo_id)}
              width={"3.75em"}
              height={"3.75em"}
              fit="cover"
              borderRadius="5px"
            />
          )}
          <VStack align="stretch" spacing={0} width="100%">
            <Link
              as={RouterLink}
              variant="unstyled"
              to={eventShowUrl(event.id)}
            >
              <Text fontSize="xl" fontWeight="semibold">
                {event.name}
              </Text>
            </Link>
            <Text fontSize="xs">{longformEventDate}</Text>
          </VStack>
        </HStack>

        {showOrganization && organization && (
          <Link
            as={RouterLink}
            variant="unstyled"
            to={organizationShowUrl(organization.id)}
          >
            <Text mb={1} fontSize="lg" fontWeight={"light"}>
              {organization.name}
            </Text>
          </Link>
        )}

        <HStack>
          <Box>
            {isSingleDayEvent && (
              <HStack alignItems="center">
                <Icon as={BiTime} boxSize={"16px"} />
                <Text fontSize="sm">{`${startTimeString} - ${endTimeString}`}</Text>
              </HStack>
            )}

            {event.location && (
              <HStack alignItems="center">
                <Icon as={BiWorld} boxSize={"16px"} />
                <Text fontSize="sm">{event.location}</Text>
              </HStack>
            )}
            {instructor && (
              <HStack alignItems="center">
                <Icon as={BiUser} boxSize={"16px"} />
                <Text fontSize="sm">{instructor?.full_name}</Text>
              </HStack>
            )}
          </Box>
        </HStack>

        {event.description && (
          <Button
            variant="unstyled"
            size={"xs"}
            width="full"
            display="flex"
            justifyContent="flex-start"
            onClick={toggleDescriptionVisibility}
            padding={0}
            mt={1}
          >
            <HStack alignItems="center">
              <Text fontSize="sm">Description</Text>
              <Icon
                as={descriptionVisible ? ChevronUpIcon : ChevronDownIcon}
                boxSize={"16px"}
              />
            </HStack>
          </Button>
        )}
        {descriptionVisible && (
          <Text
            fontSize="sm"
            dangerouslySetInnerHTML={{ __html: html }}
            pb={8}
          />
        )}

        <Box position="absolute" bottom="1" right="1">
          <Link
            as={RouterLink}
            to={
              tickets.length > 0
                ? eventTicketsUrl(event.id)
                : eventShowUrl(event.id, { ticket_selection: true })
            }
          >
            <Button variant="primary" size="xs" isDisabled={isEventOver}>
              {isEventOver
                ? "This event is over"
                : tickets.length > 0
                ? "Tickets"
                : "Get Tickets"}
            </Button>
          </Link>
        </Box>
      </VStack>
    </Box>
  );
};
