import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchUnsignedWaivers } from "../../services/api/payments";
import { useCurrentUser } from "../../providers/CurrentUserProvider";
import { SignWaiverModal } from "./SignWaiverModal";
import { Waiver } from "../../types/waiver";
import { authUrl, waiverSignAllUrl } from "../../services/routes/urlBuilder";
import { Organization } from "../../types/organization";
interface SignAllWaiversModalsProps {
  organization: Organization;
  redirectTo?: string;
  onClose?: () => void;
  onBeforeComplete?: () => Promise<void>;
}

export const SignAllWaiversModals: React.FC<SignAllWaiversModalsProps> = ({
  organization,
  redirectTo,
  onClose,
  onBeforeComplete,
}) => {
  const navigate = useNavigate();
  const { currentUser, initialized } = useCurrentUser();
  const [waivers, setWaivers] = useState<Waiver[]>([]);

  useEffect(() => {
    const init = async () => {
      if (!organization) return;

      if (initialized && !currentUser) {
        navigate(authUrl(waiverSignAllUrl(organization.slug, { redirectTo })));
      }

      const unsignedWaivers = await fetchUnsignedWaivers(organization.id);
      await setWaivers(unsignedWaivers);

      if (unsignedWaivers.length === 0) {
        if (onBeforeComplete) {
          await onBeforeComplete();
        }

        if (redirectTo) {
          navigate(redirectTo);
        }
      }
    };

    init();
  }, [
    currentUser,
    organization,
    navigate,
    redirectTo,
    initialized,
    onBeforeComplete,
  ]);

  const handleComplete = async () => {
    const remainingWaivers = waivers.slice(1);
    setWaivers(remainingWaivers);

    if (remainingWaivers.length === 0) {
      if (onBeforeComplete) {
        await onBeforeComplete();
      }

      if (redirectTo) {
        navigate(redirectTo);
      }
    }
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const currentWaiver = waivers[0];

  if (!currentWaiver) return null;

  return (
    <SignWaiverModal
      waiver={currentWaiver}
      isOpen={true}
      onClose={handleClose}
      onComplete={handleComplete}
    />
  );
};
