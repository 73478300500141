import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const OldOrganizationsRedirect = () => {
  const { pathname, search } = useLocation();
  useEffect(() => {
    const nextUrl = pathname.replace("/organizations/", "/o/") + search;
    window.location.href = nextUrl;
  }, [pathname, search]);

  return null;
};
