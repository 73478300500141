import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Spacer,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { mobileModalThemeProps } from "../services/theme/overrides/modal";
import { useAllCurrentUserFriendships } from "../hooks/useAllCurrentUserFriendships";
import { useEffect, useMemo, useState } from "react";
import { TEXT_SECONDARY_COLOR } from "../services/theme/colors";
import { UserAvatar } from "./UserAvatar";
import { useCurrentUserConnections } from "../hooks/useCurrentUserConnections";
import { Panel } from "./Panel";
import { StyledInput } from "./forms/StyledInput";
import { VianikoUser } from "../types/users";
import { CopyToClipboardIconButton } from "./CopyToClipboardIconButton";
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { BsFillChatFill } from "react-icons/bs";
import { isMobileBrowser } from "../services/isMobileBrowser";
import { NavLink } from "react-router-dom";

export type SocialSharingOptions = {
  whatsappText: string;
  smsText?: string;
}

interface InviteFriendsModalProps {
  isOpen: boolean;
  onClose: () => void;
  inviteLink: string;
  onInvite?: (userId: string) => Promise<any>;
  heading?: string;
  renderModalBodyTop?: React.ReactNode;
  excludeUserIds?: string[];
  socialSharingOptions?: SocialSharingOptions;
}

type InvitedUser = VianikoUser & {
  num_connections: number;
  invited: boolean;
};

export const InviteFriendsModal: React.FC<InviteFriendsModalProps> = ({
  isOpen,
  onClose,
  inviteLink,
  onInvite,
  heading,
  renderModalBodyTop,
  excludeUserIds,
  socialSharingOptions,
}) => {
  const [filterText, setFilterText] = useState("");

  const { friends } = useAllCurrentUserFriendships();
  const { userConnections } = useCurrentUserConnections();
  const [locallyInvitedUserIds, setLocallyInvitedUserIds] = useState<string[]>(
    []
  );

  const isMobile = isMobileBrowser();

  const connections = useMemo(() => {
    if (!friends || !userConnections) return [];
    const filteredUsers = userConnections
      .concat(friends)
      .filter(
        (user, index, self) => index === self.findIndex((t) => t.id === user.id)
      )
      .filter((user) => !excludeUserIds || !excludeUserIds.includes(user.id));
    return filteredUsers.map((user) => ({
      ...user,
      invited: locallyInvitedUserIds.includes(user.id),
    })) as InvitedUser[];
  }, [friends, userConnections, excludeUserIds, locallyInvitedUserIds]);

  const filteredConnections = useMemo(() => {
    if (!filterText) return connections;
    return connections.filter(
      (connection) =>
        connection.full_name
          ?.toLowerCase()
          .includes(filterText.toLowerCase()) || false
    );
  }, [connections, filterText]);

  const modalContentProps = useBreakpointValue({
    base: mobileModalThemeProps,
    lg: {},
  });

  useEffect(() => {
    if (isOpen) {
      setLocallyInvitedUserIds([]);
    }
  }, [isOpen]);

  const handleInvite = async (userId: string) => {
    const inviteResult = await onInvite?.(userId);
    if (inviteResult === "") {
      setLocallyInvitedUserIds((prev) => [...prev, userId]);
    }
  };

  return (
    <Portal>
      <Modal
        size="md"
        scrollBehavior="inside"
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent {...modalContentProps}>
          <ModalHeader>
            <Heading as="h2" size="xl">
              {heading || "Invite people"}
            </Heading>
          </ModalHeader>
          <ModalCloseButton margin={2}>
            <Icon as={AiOutlineCloseCircle} fontSize={36} />
          </ModalCloseButton>
          <ModalBody paddingBottom={0} paddingX={"20px"}>
            <>
              {renderModalBodyTop}

              <Panel
                mt={2}
                mb={0}
                borderTopRadius="24px"
                borderBottomRadius={0}
              >
                <VStack align={"left"} spacing={4}>
                  <Heading>Invite at least one friend</Heading>

                  {connections && connections.length > 0 && (
                    <StyledInput
                      placeholder="Search..."
                      name="filter_connections"
                      isRequired={true}
                      onChange={(e) => setFilterText(e.target.value)}
                      value={filterText}
                    />
                  )}

                  {filteredConnections && filteredConnections.length > 0 && (
                    <VStack gap={4}>
                      {filteredConnections.map((userConnection) => (
                        <HStack width="100%" key={userConnection.id}>
                          <UserAvatar size="md" user={userConnection} />
                          <Box>
                            <Text>{userConnection.full_name}</Text>
                            {userConnection.num_connections &&
                              userConnection.num_connections > 0 && (
                                <Text size="sm" color={TEXT_SECONDARY_COLOR}>
                                  {userConnection.num_connections}{" "}
                                  {userConnection.num_connections === 1
                                    ? "time"
                                    : "times"}{" "}
                                  together
                                </Text>
                              )}
                          </Box>
                          <Spacer />
                          {userConnection.invited ? (
                            <Button
                              color={TEXT_SECONDARY_COLOR}
                              disabled={true}
                            >
                              Invited
                            </Button>
                          ) : (
                            <Button
                              variant="primary"
                              onClick={() => handleInvite(userConnection.id)}
                            >
                              Invite
                            </Button>
                          )}
                        </HStack>
                      ))}
                    </VStack>
                  )}
                </VStack>
              </Panel>
            </>
          </ModalBody>
          <ModalFooter paddingTop={0} paddingX={"20px"}>
            <Box position="relative" width={"100%"}>
              {connections && connections.length > 0 && <Divider
                position="absolute"
                top="0"
                left="5%"
                width="90%"
                borderColor="gray.300"
              />}
              <Panel
                p={4}
                width="100%"
                mt={0}
                borderTopRadius={0}
                borderBottomRadius="24px"
              >
                <HStack align={"center"} justify={"center"} width={"100%"}>
                  <CopyToClipboardIconButton text={inviteLink} />
                  <FacebookShareButton url={inviteLink}>
                    <FacebookIcon size={40} round />
                  </FacebookShareButton>

                  <WhatsappShareButton
                    url={inviteLink}
                    title={socialSharingOptions?.whatsappText || "You're invited to Feather!"}
                  >
                    <WhatsappIcon size={40} round />
                  </WhatsappShareButton>
                  
                  {isMobile && (
                    <IconButton
                      as={NavLink}
                      aria-label="Send SMS"
                      to={`sms:?body=${encodeURIComponent((socialSharingOptions?.smsText || "You're invited to Feather! ") + inviteLink)}`}
                      icon={<Icon as={BsFillChatFill} color="white" />}
                      bgGradient="linear(to-b, #84fc6e, #16d71e)"
                      borderRadius="full"
                    />
                  )}
                </HStack>
              </Panel>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Portal>
  );
};
