import { Box, Button, Heading, HStack, Spacer, Text } from "@chakra-ui/react";
import { priceInDollars } from "../../../services/payments";
import { TEXT_SECONDARY_COLOR } from "../../../services/theme/colors";
import { Pass } from "../../../types/passes";
import { ButtonLink } from "../../../components/ButtonLink";
import {
  passPaymentUrl,
  waiverSignAllUrl,
} from "../../../services/routes/urlBuilder";
import { usePasses } from "../../passes/hooks/usePasses";
import { PassAboutText } from "./PassAboutText";
import { useMemo } from "react";
import { Organization } from "../../../types/organization";
import { DEFAULT_CURRENCY_ISO_CODE } from "../../../services/payments";
import { FeesAndTaxesIndicator } from "../../../components/FeesAndTaxesIndicator";

interface PassInfoProps {
  pass: Pass;
  organization: Organization;
}

export const PassInfo: React.FC<PassInfoProps> = ({ pass, organization }) => {
  const { currentUserPasses } = usePasses(pass.organization_id);

  const userPass = useMemo(() => {
    return currentUserPasses?.find(
      (user_pass) => user_pass.pass_id === pass.id
    );
  }, [currentUserPasses, pass.id]);

  console.log("pass", pass);

  return (
    <>
      <HStack align="start">
        <Box>
          <Heading as="h3" size="md">
            {pass.name}
          </Heading>

          <HStack align="center" marginY={1}>
            <Text size="md" color={TEXT_SECONDARY_COLOR}>
              {priceInDollars(
                organization.currency_iso_code ?? DEFAULT_CURRENCY_ISO_CODE,
                pass.price_in_cents
              )}
            </Text>

            <FeesAndTaxesIndicator
              currencyIsoCode={
                organization.currency_iso_code ?? DEFAULT_CURRENCY_ISO_CODE
              }
              feesInCents={pass.fees_in_cents}
              taxesInCents={pass.taxes_in_cents}
              includeFeesInPrices={pass.include_fees_in_prices}
              includeTaxesInPrices={pass.include_taxes_in_prices}
            />
          </HStack>

          <PassAboutText pass={pass} userPass={userPass} />

          {pass.description && (
            <Text size="md" color={TEXT_SECONDARY_COLOR}>
              {pass.description}
            </Text>
          )}
        </Box>

        <Spacer />

        <HStack>
          {userPass?.is_valid ? (
            <Button isDisabled variant="primary" size="sm">
              Owned
            </Button>
          ) : (
            <ButtonLink
              to={waiverSignAllUrl(organization.slug, {
                redirectTo: passPaymentUrl(pass.id),
              })}
              buttonProps={{
                variant: "primary",
                size: "sm",
              }}
            >
              Buy
            </ButtonLink>
          )}
        </HStack>
      </HStack>
    </>
  );
};
