import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAuth } from "./AuthProvider";
import * as api from "../services/api";
import { EventUser } from "../types/event_users";

interface EventUserContext {
  currentEventUser: EventUser | undefined;
  refetch: () => void;
  initialized: boolean;
}

const CurrentEventUserContext = createContext<EventUserContext>({
  currentEventUser: undefined,
  refetch: () => {},
  initialized: false,
});

interface CurrentEventUserProviderProps {
  eventId: string;
  children: React.ReactNode;
}

export const CurrentEventUserProvider = ({
  eventId,
  children,
}: CurrentEventUserProviderProps) => {
  const { user: authUser, initialized: authUserInitialized } = useAuth();
  const [eventUserInitialized, setEventUserInitialized] = useState(false);
  const [eventUser, setEventUser] = useState<EventUser | undefined>(undefined);
  const fetchEventUser = useCallback(async () => {
    if (authUserInitialized) {
      if (authUser) {
        try {
          const nextEventUser = await api.eventUsers.fetchCurrentEventUser(
            eventId
          );
          setEventUser(nextEventUser);
        } catch (error) {
          setEventUser(undefined);
        }
      } else {
        setEventUser(undefined);
      }

      setEventUserInitialized(true);
    }
  }, [authUserInitialized, authUser, eventId]);

  useEffect(() => {
    fetchEventUser();
  }, [fetchEventUser]);

  const refetch = useCallback(() => {
    fetchEventUser();
  }, [fetchEventUser]);

  return (
    <CurrentEventUserContext.Provider
      value={{
        currentEventUser: eventUser,
        refetch,
        initialized: eventUserInitialized,
      }}
    >
      {children}
    </CurrentEventUserContext.Provider>
  );
};

export const useCurrentEventUser = (): EventUserContext => {
  return useContext(CurrentEventUserContext);
};
